/**
 * Binti-customized ApolloProvider for react_component-rendered components
 * that need to connect to our Binti graphql via the ApolloProvider.
 *
 * All components are auto-wrapped with this by `react_component` in the
 * app itself via ReactRailsApolloWrapper, but you can use this provider
 * in stories because they don't use `react_component`. For specs, try
 * MockedProvider from @apollo/client/testing.
 */
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  makeVar,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { get, sortBy, zip } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import Modal from "react-modal";

import { isPrerendered, isTestEnvironment } from "@lib/environment";
import squish from "@lib/squish";

import { GRAPHQL_POSSIBLE_TYPES } from "@root/constants";

export const ssoSearchTermVar = makeVar(null);
export const queryVariablesVar = makeVar(null);

const SKIP_MUTATION_ERROR_HANDLING = [
  "CreateOrUpdatePermanencyGoal",
  "UpdateUser",
  "UpdateAgencyPlacement",
  "UpdatePlacementProvider",
  "SendOtp",
  "CreatePlacementSearch",
  "CreatePlacementPeriod",
];

const cache = new InMemoryCache({
  possibleTypes: GRAPHQL_POSSIBLE_TYPES,
  typePolicies: {
    ResourceAction: {
      keyFields: ["roleId", "permissionRuleSetId", "resource", "action"],
    },
    Query: {
      fields: {
        resourceAction: {
          read: (_, { args, toReference }) => {
            const prsId = args.permissionRuleSetId;
            return toReference({
              __typename: "ResourceAction",
              permissionRuleSetId: prsId ? String(prsId) : prsId,
              roleId: args.roleId ? String(args.roleId) : args.roleId,
              resource: args.resource,
              action: args.action,
            });
          },
        },
        applicationStageTemplate: {
          read: (_, { args: { id }, toReference }) =>
            toReference({
              __typename: "ApplicationStageTemplate",
              id: id ? String(id) : id,
            }),
        },
        hearingSession: {
          read: (_, { args: { id }, toReference }) =>
            toReference({
              __typename: "HearingSession",
              id: id ? String(id) : id,
            }),
        },
        ssoLogin: {
          read: () => ({
            ssoSearchTerm: ssoSearchTermVar(),
          }),
        },
      },
    },

    RequirementReconciliation: {
      // RequirementReconciliations have a RequirementHolder that may be a Child
      // or an Application. In case there is a collision in their keyspace, use
      // a special field that prepends the base type
      keyFields: ["holderToken"],
    },

    FormInstance: {
      fields: {
        attachments: {
          merge: (_, incoming) =>
            // Override with incoming, usually because we've deleted the attachments.
            // This is the default behavior, but want to make it explicit to avoid
            // console warnings.
            // See https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays
            incoming,
        },
      },
    },

    RequiredSignature: {
      fields: {
        signedLocations: {
          merge: (_, incoming) => incoming,
        },
      },
    },

    SigningEvent: {
      fields: {
        // pdfContents is expensive to generate, the frontend is basically
        // the cache for it, and it won't change within a single page, so
        // never discard it once we have it.
        pdfContents: {
          merge: (existing, incoming) => existing ?? incoming,
        },
        requiredSignatures: {
          merge: (existing, incoming) => {
            if (!existing) {
              return incoming;
            }

            // We get locations with the `refreshedLocations` flag set to true
            // only when requesting `pdfContents`, so treat these as special
            // and expensive just like them and hold onto them.
            return zip(
              // sort by role so we match like with like
              sortBy(existing, ["role"]),
              sortBy(incoming, ["role"])
            ).map(([existingRs, incomingRs]) => {
              if (existingRs.refreshedLocations) {
                return {
                  ...incomingRs,
                  refreshedLocations: true,
                  locations: existingRs.locations,
                };
              }

              return incomingRs;
            });
          },
        },
      },
    },
  },
});

const isMutation = operation =>
  get(operation, "query.definitions[0].operation") === "mutation";

/* eslint-disable no-alert, no-console */
const createLink = setShowBetterErrors =>
  from([
    onError(({ graphQLErrors, networkError, response, operation }) => {
      if (setShowBetterErrors && networkError) {
        setShowBetterErrors(true);
        return;
      }

      if (!isMutation(operation)) {
        // only show this warning on mutations. read queries can display
        // inline.
        return;
      }

      if (
        get(networkError, "statusCode") === 401 ||
        get(networkError, "statusCode") === 403 ||
        get(graphQLErrors, "[0].message") === "Unauthorized"
      ) {
        window.alert(squish`
        Looks like you don't have permission to do that. If you believe
        this is an error, please contact our support team.`);

        // clear errors so apollo doesn't throw - the alert is sufficient
        if (response) {
          response.errors = null;
        }
        return;
      }

      if (get(graphQLErrors, "[0].message") === "virus_detected") {
        window.alert(I18n.t("activerecord.errors.messages.virus_detected"));

        // clear errors so apollo doesn't throw - the alert is sufficient
        if (response) {
          response.errors = null;
        }
        return;
      }

      if (
        SKIP_MUTATION_ERROR_HANDLING.includes(operation.operationName) ||
        operation.getContext().bintiSkipMutationErrorHandling
      ) {
        return;
      }

      if (networkError || graphQLErrors.length > 0) {
        console.log(JSON.stringify({ networkError, graphQLErrors }));
        window.alert(I18n.t("javascript.components.common.generic_error"));

        // Don't reload in development because that keeps us from seeing the error.
        // Checking `process.env.NODE_ENV` is standard practice in Webpack [1] but please
        // use this technique sparingly as we want very few differences between our
        // development and production bundles.
        //
        // [1]: https://webpack.js.org/guides/production/#specify-the-mode
        if (process.env.NODE_ENV === "production") {
          window.location.reload();
        }
      }
    }),
    createUploadLink(),
  ]);
/* eslint-enable no-alert, no-console */

let apolloClient = null;

// only use this modal in development where we can actually see it.
//
// the modal also gets in the way in test environments because graphql
// requests can be inflight when we're doing test db setup, fail, then
// cause this modal to pop up when we don't care about the error.
const DevelopmentProvider = ({ children }) => {
  const [showBetterErrors, setShowBetterErrors] = useState(false);

  if (!apolloClient) {
    apolloClient = new ApolloClient({
      cache,
      link: createLink(setShowBetterErrors),
    });
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Modal isOpen={showBetterErrors}>
        <iframe
          src="/__better_errors"
          title="Better errors"
          style={{ height: "100%", width: "100%" }}
        />
      </Modal>
      {children}
    </ApolloProvider>
  );
};

DevelopmentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const ProductionProvider = ({ children }) => {
  if (!apolloClient) {
    apolloClient = new ApolloClient({
      cache,
      link: createLink(),
    });
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

ProductionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const BintiApolloProvider = ({ children }) => {
  if (
    !isPrerendered() &&
    !isTestEnvironment() &&
    process.env.NODE_ENV !== "production"
  ) {
    return <DevelopmentProvider>{children}</DevelopmentProvider>;
  }

  return <ProductionProvider>{children}</ProductionProvider>;
};

BintiApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BintiApolloProvider;
