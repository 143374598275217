import { useMutation } from "@apollo/client";
import {
  SurfaceForm,
  InputFilterable,
  MultiInputTemplate,
  InputDate,
  InputDropdown,
  InputRadioGroup,
  InputText,
  InputMoney,
  Actions,
  SurfaceFieldset,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { agencyServicePath } from "routes";

import { translationWithRoot } from "@components/T";

import CreatePreventionAgencyService from "@graphql/mutations/CreatePreventionAgencyService.graphql";

import preventDefault from "@lib/preventDefault";

const { T, t } = translationWithRoot("prevention.agency_services");
const { T: TRate, t: tRate } = translationWithRoot(
  "prevention.agency_service_rates"
);

const AgencyServiceForm = ({
  providerAgencies,
  referringAgencies,
  ebps,
  areasOfCoverage,
  fundingSources,
  serviceTypes,
  billingStructures,
}) => {
  const [error, setError] = useState(null);

  const checkForErrors = sections => {
    if (sections.length === 0) {
      setError(t("errors.add_service_rate"));
    } else {
      setError(null);
    }
  };

  const [createPreventionAgencyService] = useMutation(
    CreatePreventionAgencyService,
    {
      onCompleted: mutationData => {
        window.location = agencyServicePath(
          mutationData.createPreventionAgencyService.agencyService.id
        );
      },
    }
  );

  const { formState, setFormAttribute } = useBintiForm(null, {
    initialValue: { agencyServiceRates: [] },
  });

  const multiUpdate = value => {
    if (!_.isEqual(value, formState.agencyServiceRates)) {
      setFormAttribute("agencyServiceRates")(value);
    }
  };

  const handleSubmit = () => {
    if (formState.agencyServiceRates.length === 0) {
      setError(t("errors.add_service_rate"));
      return;
    }

    createPreventionAgencyService({
      variables: {
        input: {
          ...formState,
          fundingSources: formState.fundingSources?.map(({ value }) => value),
          serviceTypes: formState.serviceTypes?.map(({ value }) => value),
          areasOfCoverage: formState.areasOfCoverage?.map(({ value }) => value),
        },
      },
    });
  };

  return (
    <SurfaceForm
      actions={<Actions />}
      title={<T t="add_service" />}
      onSubmit={preventDefault(handleSubmit)}
    >
      <If condition={referringAgencies}>
        <InputDropdown
          hideBlank
          label={<T t="referring_agency" />}
          onChange={setFormAttribute("referringAgencyId")}
          required
          values={referringAgencies}
        />
      </If>
      <InputDropdown
        hideBlank
        label={<T t="provider" />}
        onChange={setFormAttribute("providerAgencyId")}
        required
        values={providerAgencies}
      />
      <InputText
        label={<T t="service_name" />}
        onChange={setFormAttribute("name")}
        required
      />
      <InputRadioGroup
        label={<T t="ffpsa_program" />}
        name="ffpsa-program"
        onChange={setFormAttribute("isFfpsa")}
        orientation="row"
        required
        values={[
          { value: true, label: t("ffpsa_yes") },
          { value: false, label: t("ffpsa_no") },
        ]}
      />
      <InputDropdown
        label={<T t="corresponding_ebp_if_applicable" />}
        required
        onChange={setFormAttribute("evidenceBasedPracticeId")}
        values={ebps}
      />
      <InputFilterable
        label={<T t="funding_sources" />}
        onChange={setFormAttribute("fundingSources")}
        name="funding-sources"
        required
        isClearable
        isMulti
        allowCreate
        values={fundingSources}
      />
      <InputFilterable
        label={<T t="service_types" />}
        onChange={setFormAttribute("serviceTypes")}
        name="service-types"
        isClearable
        isMulti
        allowCreate
        values={serviceTypes}
      />
      <InputFilterable
        label={<T t="areas_of_coverage" />}
        onChange={setFormAttribute("areasOfCoverage")}
        name="areas-of-coverage"
        isClearable
        isMulti
        values={areasOfCoverage}
      />
      <SurfaceFieldset title={<T t="add_rate_section_title" />}>
        <MultiInputTemplate
          error={error}
          checkForErrors={checkForErrors}
          addSectionText={tRate("add")}
          hideTitle
          defaultValues={{}}
          onChange={multiUpdate}
          onDelete={() => {}}
          removeSectionText={tRate("remove")}
          sectionInputs={({ getMultiInputProps }) => {
            // collect the billingStructure props here so we can use it
            // for the conditional below
            const billingStructure = getMultiInputProps({
              id: "billingStructure",
            });

            return (
              <Fragment>
                <InputText
                  {...getMultiInputProps({ id: "label" })}
                  label={<TRate t="label" />}
                  required
                />
                <InputText
                  {...getMultiInputProps({ id: "code" })}
                  label={<TRate t="code" />}
                  description={<TRate t="code_description" />}
                  required
                />
                <InputDate
                  {...getMultiInputProps({ id: "startDate" })}
                  label={<TRate t="start_date" />}
                  required
                />
                <InputDate
                  {...getMultiInputProps({ id: "endDate" })}
                  label={<TRate t="end_date" />}
                  required
                />
                <InputDropdown
                  {...getMultiInputProps({ id: "billingStructure" })}
                  id="dropdown-id"
                  label={<TRate t="billing_structure" />}
                  values={billingStructures}
                  required
                />
                <InputMoney
                  {...getMultiInputProps({ id: "amount" })}
                  label={<TRate t="amount" />}
                />
                <If condition={billingStructure.value === "fee_per_service"}>
                  <InputText
                    {...getMultiInputProps({ id: "sessionsPerService" })}
                    label={<TRate t="sessions_per_service" />}
                    type="number"
                    required
                  />
                </If>
              </Fragment>
            );
          }}
          startingValues={[]}
          title={<TRate t="title" />}
        />
      </SurfaceFieldset>
    </SurfaceForm>
  );
};

AgencyServiceForm.propTypes = {
  providerAgencies: PropTypes.array,
  referringAgencies: PropTypes.array,
  ebps: PropTypes.array,
  areasOfCoverage: PropTypes.array,
  fundingSources: PropTypes.array,
  serviceTypes: PropTypes.array,
  billingStructures: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default AgencyServiceForm;
