import { Actions, generateTabParam, Text } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";
import {
  contentFamilyFindingBulkOutreachCampaignPath,
  editFamilyFindingBulkOutreachCampaignPath,
  relationshipsDashboardAdminChildPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import { getEncodedBase64SearchParams } from "@lib/base64SearchParams";

import { B64PARAMS } from "@root/constants";

import styles from "./BulkOutreachCampaignPreview.module.scss";

const { t } = translationWithRoot(
  "family_finding.bulk_outreach_campaigns.preview"
);

const BulkOutreachCampaignPreview = ({ id, childId }) =>
  id ? (
    <Fragment>
      <Text textColor="emphasis-500" textStyle="emphasis-100">
        {t("preview_letter")}
      </Text>
      <div className={styles.preview}>
        <iframe
          title="bulkOutreachCampaignLetterPreview"
          src={contentFamilyFindingBulkOutreachCampaignPath(id)}
          className={styles.iframe}
        />
      </div>
      <Actions
        primaryText={t("generate_letters")}
        primaryAction={() => {
          window.location = `${relationshipsDashboardAdminChildPath({
            id: childId,
          })}?${generateTabParam("Contact Logs")}`;
          window
            .open(
              `${contentFamilyFindingBulkOutreachCampaignPath(id)}`,
              "_blank"
            )
            .focus();
        }}
        secondaryText={t("edit")}
        secondaryHref={`${editFamilyFindingBulkOutreachCampaignPath(
          id
        )}?${B64PARAMS}=${getEncodedBase64SearchParams()}`}
        cancelHref={relationshipsDashboardAdminChildPath({ id: childId })}
      />
    </Fragment>
  ) : null;
BulkOutreachCampaignPreview.propTypes = {
  id: PropTypes.string.isRequired,
  childId: PropTypes.number.isRequired,
};

export default BulkOutreachCampaignPreview;
