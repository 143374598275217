import { Icons, Flex, Text, Actions } from "@heart/components";
import I18n from "i18n-js";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import preventDefault from "@lib/preventDefault";

import styles from "./Modal.module.scss";
import ModalTitle from "./ModalTitle";
import ModalWrapper from "./ModalWrapper";

/** A modal! This component should be used when showing
 * informational or interactable content. When showing an
 * alert, the Alert component should be used instead.
 */

const Modal = ({
  cancelHref,
  cancelText = I18n.t("views.common.cancel"),
  children,
  displayLargeTitle = false,
  hidden,
  onCancel,
  onSubmit,
  submitting,
  submitDangerButton,
  submitDisabled,
  submitHref,
  submitText = I18n.t("views.common.submit"),
  submittingText = I18n.t("views.common.submitting"),
  hideCancel,
  title,
  asForm,
  "data-testid": testId,
}) => {
  const generatedId = uniqueId("modal");

  const wrapperProps = asForm
    ? {
        as: "form",
        onSubmit:
          preventDefault(e => {
            e.stopPropagation();
            onSubmit(e);
          }) ||
          (() => {
            window.location.href = submitHref;
          }),
      }
    : {};
  const submitButtonProps = asForm
    ? { type: "submit" }
    : {
        primaryAction: onSubmit,
        primaryHref: submitHref,
      };

  return (
    <ModalWrapper
      id={generatedId}
      hidden={hidden}
      onCancel={onCancel}
      data-testid={testId}
      {...wrapperProps}
    >
      <Flex row justify="space-between" className={styles.modalTitle}>
        <ModalTitle
          displayLargeTitle={displayLargeTitle}
          title={title}
          id={`modal-header-${generatedId}`}
        />
        <Icons.Times
          description="Close Modal"
          onClick={onCancel}
          disabled={submitting}
        />
      </Flex>
      <If condition={children}>
        <Text as="div" textStyle="body-100" className={styles.modalBody}>
          {children}
        </Text>
      </If>
      <div className={styles.modalActions}>
        <Actions
          formId={asForm ? generatedId : undefined}
          cancelAction={hideCancel ? undefined : onCancel}
          cancelHref={hideCancel ? undefined : cancelHref}
          isSubmitting={submitting}
          cancelText={cancelText}
          primarySubmittingText={submittingText}
          primaryIsDanger={submitDangerButton}
          primaryText={submitText}
          primaryDisabled={submitDisabled}
          {...submitButtonProps}
        />
      </div>
    </ModalWrapper>
  );
};

Modal.propTypes = {
  /** Link to navigate to on cancel */
  cancelHref: PropTypes.string,
  /** Text for cancel button */
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Modal contents */
  children: PropTypes.node,
  /** Whether title should be displayed larger than the
   * rest of the contents of the modal or not. Note: regardless
   * of whether the title is displayed as a title or as regular
   * text, the title is required for accessibility reasons */
  displayLargeTitle: PropTypes.bool,
  /** Whether modal should be hidden or not */
  hidden: PropTypes.bool.isRequired,
  /** Action to take when cancel button is clicked */
  onCancel: PropTypes.func,
  /** Action to take when submit button is clicked */
  onSubmit: PropTypes.func,
  /** Whether submitting button spinner should be displayed or not. Also
   * disables modal buttons when `true`
   */
  submitting: PropTypes.bool,
  /** Whether submit button should be a danger button */
  submitDangerButton: PropTypes.bool,
  /** Whether submit button should be disabled */
  submitDisabled: PropTypes.bool,
  /** Link to navigate to on submit */
  submitHref: PropTypes.string,
  /** Text for submit button */
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Text for submit button when submit is in progress */
  submittingText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Title of modal */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** When true, will hide the cancel button of the modal. This is primarily useful
   * when the modal is purely informational, with no actions to take. Otherwise an
   * Alert should be used in the case where we want to avoid letting users close the
   * dialog without taking action
   */
  hideCancel: PropTypes.bool,
  /** Set asForm if the modal contains a form. This pattern should be avoided
   * wherever possible, as forms within a modal isn't an ideal UX. If we've
   * cleared the decision to use a form within a modal, this prop will switch
   * things within the modal to allow us to use HTML form validations.
   */
  asForm: PropTypes.bool,
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};
export default Modal;
