import { UploadButton, Flex } from "@heart/components";
import classnames from "classnames";
import PropTypes from "prop-types";

import T from "@components/T";
import { Caption, Table, Tbody, Td, Tr } from "@components/reusable_ui/Table";
import useUpload from "@components/reusable_ui/useUpload";
import useUploadStyles from "@components/reusable_ui/useUpload.module.scss";

import { typeEq } from "@lib/graphqlHelpers";

import styles from "../requirements/Requirement.module.scss";
import UnmatchedApplicationAttachmentRow from "./UnmatchedApplicationAttachmentRow";
import UnmatchedFormInstanceRow from "./UnmatchedFormInstanceRow";
import UnmatchedUploadedRecordRow from "./UnmatchedUploadedRecordRow";

const RowForRecord = ({ application, aroundLoader, record }) => {
  if (typeEq("UploadedRecord", record)) {
    return (
      <UnmatchedUploadedRecordRow {...{ application, aroundLoader, record }} />
    );
  }

  if (typeEq("Attachment", record) || typeEq("ApplicationMiscFile", record)) {
    return (
      <UnmatchedApplicationAttachmentRow
        {...{ application, aroundLoader, record }}
      />
    );
  }

  if (
    typeEq("FormInstance", record) &&
    record.attachments &&
    record.attachments.length > 0
  ) {
    return <UnmatchedFormInstanceRow {...{ record, application }} />;
  }

  return false;
};

RowForRecord.propTypes = {
  application: PropTypes.object.isRequired,
  aroundLoader: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

const UnmatchedRecordsTable = ({
  title,
  records,
  application,
  uploadFiles,
}) => {
  const { getRootProps, getInputProps, isDragActive, aroundLoader } =
    useUpload(uploadFiles);

  const { className, ...rootProps } = getRootProps();

  const wrapperProps = {
    ...rootProps,
    className: classnames(
      className,
      { [useUploadStyles.dragActive]: isDragActive },
      styles.breakoutTable,
      "test-application-requirement-table"
    ),
  };

  // There must be some records and those records must have attachments
  const anyRecordsToShow =
    records.length > 0 &&
    records.some(
      record =>
        typeEq("Attachment", record) ||
        typeEq("ApplicationMiscFile", record) ||
        (record.attachments && record.attachments.length > 0)
    );

  return (
    <div {...wrapperProps}>
      <Table>
        <Caption>
          <Flex justify="space-between">
            {title}
            <UploadButton
              passedInputProps={getInputProps}
              label={I18n.t(
                "javascript.components.application_requirements.unmatched_records_table.upload_button_title"
              )}
            />
          </Flex>
        </Caption>
        <Tbody>
          <If condition={!anyRecordsToShow}>
            <Tr>
              <Td>
                <T t="application_requirements.no_downloadable_documents" />
              </Td>
            </Tr>
          </If>
          <If condition={anyRecordsToShow}>
            {records.map(record => (
              <RowForRecord
                key={`${record.__typename}-${record.id}`}
                {...{ application, aroundLoader, record }}
              />
            ))}
          </If>
        </Tbody>
      </Table>
    </div>
  );
};

UnmatchedRecordsTable.propTypes = {
  title: PropTypes.string.isRequired,
  records: PropTypes.array.isRequired,
  application: PropTypes.object.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

export default UnmatchedRecordsTable;
