import { useQuery, useReactiveVar } from "@apollo/client";
import {
  Breadcrumbs,
  Button,
  ContentTabs,
  Flex,
  Layout,
  Surface,
} from "@heart/components";
import GraphQLDataTable from "@heart/components/data_table/GraphQLDataTable";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import {
  adminChildrenPath,
  newFamilyFindingSearchPath,
  familyFindingSearchesCsvPath,
  rootPath,
} from "routes";

import T, { translationWithRoot } from "@components/T";
import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import AgencyAutocompleteQuery from "@graphql/queries/AgencyAutocomplete.graphql";
import CurrentUserQuery from "@graphql/queries/CurrentUser.graphql";
import SearchesQuery from "@graphql/queries/Searches.graphql";

import {
  getBase64SearchParamForAttribute,
  setBase64SearchParams,
} from "@lib/base64SearchParams";
import { policy } from "@lib/graphqlHelpers";

import getColumns from "./columns";

const { t } = translationWithRoot("family_finding.searches");

const Searches = () => {
  const Table = ({ setCounts, tab }) => {
    const { data: currentUser } = useQuery(CurrentUserQuery);

    const [permissions, setPermissions] = useState([]);
    const onQueryCompleted = searches => {
      const perms = searches?.familyFindingSearches?.permissions;
      if (perms) {
        setPermissions(perms);
      }

      setCounts({
        open: get(searches, "openSearches.totalCount"),
        closed: get(searches, "closedSearches.totalCount"),
        all: get(searches, "allSearches.totalCount"),
      });
    };
    const perms = policy({ permissions });

    const filters = [
      {
        label: t("filters.search_for_children"),
        type: "search",
        field: "childNameContains",
      },
      {
        label: "Search",
        customDatesLabels: {
          start: t("filters.opened_after"),
          end: t("filters.opened_before"),
        },
        type: "custom_dates",
        field: "startDate",
      },
      tab !== "open" && {
        label: "Search",
        customDatesLabels: {
          start: t("filters.closed_after"),
          end: t("filters.closed_before"),
        },
        type: "custom_dates",
        field: "endDate",
      },
      {
        label: "Worker Assigned",
        type: "search",
        field: "workerNameContains",
      },
      currentUser?.user?.bintiAdmin && {
        label: "Agency",
        type: "autocomplete_select",
        field: "agencyEq",
        query: AgencyAutocompleteQuery,
        valuesFromResponse: ({ agencyMatches }) =>
          agencyMatches.map(({ name, id }) => ({ label: name, value: id })),
      },
    ].filter(Boolean);

    const defaultSortBy = tab === "closed" ? "endDate" : "startDate";

    const queryVariables = useReactiveVar(queryVariablesVar) || {};

    const paramsForCsv = `query=${encodeURI(
      JSON.stringify(queryVariables)
    )}&tab=${tab}`;

    return (
      <Surface title={t("title")} hideTitle>
        <GraphQLDataTable
          query={SearchesQuery}
          queryTitle="familyFindingSearches"
          filtersToPreserveOnClear={["status"]}
          defaultFilters={{
            /** We're using the tab selected as our default for all the
             * tabs rendered to get around a bug we were seeing where the
             * first load of the page would always land us on the open tab,
             * subsequently locking us into the open status filter
             */
            status: (
              getBase64SearchParamForAttribute({ attribute: "tab" }) || tab
            ).toLowerCase(),
          }}
          actions={
            <If condition={perms.mayCreate()}>
              <Flex justify="end" column>
                <Button href={newFamilyFindingSearchPath()} variant="primary">
                  {t("create_new")}
                </Button>
              </Flex>
            </If>
          }
          columns={getColumns({ tab, currentUser: currentUser?.user })}
          filters={filters}
          onQueryCompleted={onQueryCompleted}
          defaultSort={{ sortBy: defaultSortBy, sortDirection: "DESC" }}
        />
        <Flex justify="center">
          <Button
            variant="secondary"
            href={`${familyFindingSearchesCsvPath()}?${paramsForCsv}`}
          >
            <T t="common.download_csv" escapeJavascriptRoot />
          </Button>
        </Flex>
      </Surface>
    );
  };

  Table.propTypes = {
    setCounts: PropTypes.func.isRequired,
    tab: PropTypes.oneOf(["all", "open", "closed"]),
  };

  // if there are active filters when the tab changes, don't do anything
  // to search params (unless we are moving to open tab, in which case remove any
  // end date filters).
  // if there are no active filters, clear out search params to allow
  // default sorting to work
  const onActiveTabChange = tab => {
    const query =
      getBase64SearchParamForAttribute({ attribute: "query" }) || {};
    if (
      [
        "childNameContains",
        "workerNameContains",
        "startDateGteq",
        "startDateLteq",
        "endDateGteq",
        "endDateLteq",
      ].some(p => p in query)
    ) {
      if (tab === t("tabs.open")) {
        /** Removing the filters that aren't relevant for open queries */
        delete query.endDateGteq;
        delete query.endDateLteq;
      }
      /** Updating the status to match the current tab */
      setBase64SearchParams([
        {
          attribute: "query",
          value: { ...query, status: tab.toLowerCase() },
        },
      ]);
    } else {
      setBase64SearchParams([{ attribute: "query", value: undefined }]);
    }
  };

  const [counts, setCounts] = useState({});

  return (
    <Layout
      pageTitle={t("title")}
      breadcrumbs={
        <Breadcrumbs
          pages={[
            { href: rootPath(), label: t("breadcrumbs.home") },
            {
              href: adminChildrenPath(),
              label: t("breadcrumbs.children_youth"),
            },
            { href: "#", label: t("title") },
          ]}
        />
      }
      main={{
        content: (
          <ContentTabs
            tabs={[
              {
                title: t("tabs.open"),
                contents: useMemo(
                  () => <Table tab="open" setCounts={setCounts} />,
                  [setCounts]
                ),
                count: counts.open,
              },
              {
                title: t("tabs.closed"),
                contents: useMemo(
                  () => <Table tab="closed" setCounts={setCounts} />,
                  [setCounts]
                ),
                count: counts.closed,
              },
              {
                title: t("tabs.all"),
                contents: useMemo(
                  () => <Table tab="all" setCounts={setCounts} />,
                  [setCounts]
                ),
                count: counts.all,
              },
            ]}
            onActiveTabChange={onActiveTabChange}
          />
        ),
      }}
    />
  );
};

export default Searches;
