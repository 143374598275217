// this file manages the state of the inline editting component
// it switches back and forth between the list view and the editting form view
import { useQuery } from "@apollo/client";
import { createBrowserHistory } from "history";
import PropTypes from "prop-types";
import { Fragment, useState, useEffect } from "react";

import { translationWithRoot } from "@components/T";

import PhoneNumbersByUser from "@graphql/queries/PhoneNumbersByUser";

import preventDefault from "@lib/preventDefault";

import PhoneNumberInlineEdit from "./PhoneNumberInlineEdit";
import PhoneNumberList from "./PhoneNumberList";

const { T } = translationWithRoot("contact_page");

const PhoneNumbers = ({
  phoneNumbers,
  displayEdit,
  csrfToken,
  currentUrl,
  personType,
  personId,
  phoneNumberEditLink,
  showPhoneNumberEditLink,
}) => {
  const [showEdit, setShowEdit] = useState(displayEdit);
  // This is an experimental pattern for URL routing
  // do not copy this!
  let history = {};
  useEffect(() => {
    history = createBrowserHistory();
  });

  const { data, refetch } = useQuery(PhoneNumbersByUser, {
    variables: { personType, personId },
  });
  const latestPhoneNumbers = data ? data.phoneNumbers : phoneNumbers;

  const setContextChange = state => {
    // This is an experimental pattern for URL routing
    // do not copy this!
    history.push(`${currentUrl}?${phoneNumberEditLink}=${state}`);
    refetch();
    setShowEdit(state);
  };

  const handleSuccess = () => {
    setContextChange(false);
  };
  const handleCancel = preventDefault(() => {
    setContextChange(false);
  });
  const handleEditClick = preventDefault(() => {
    setContextChange(true);
  });
  if (showEdit) {
    return (
      <div data-testid="phone-inline-edit">
        <PhoneNumberInlineEdit
          phoneNumbers={latestPhoneNumbers}
          csrfToken={csrfToken}
          currentUrl={currentUrl}
          personType={personType}
          personId={personId}
          phoneNumberEditLink={phoneNumberEditLink}
          handleSuccess={handleSuccess}
          handleCancel={handleCancel}
        />
      </div>
    );
  }
  return (
    <Fragment>
      <PhoneNumberList
        phoneNumbers={latestPhoneNumbers}
        csrfToken={csrfToken}
        currentUrl={currentUrl}
        personId={personId}
        personType={personType}
      />
      <If condition={showPhoneNumberEditLink}>
        <a href={`?${phoneNumberEditLink}=true`} onClick={handleEditClick}>
          <T t="phone_number_edit_or_add" />
        </a>
      </If>
    </Fragment>
  );
};

PhoneNumbers.propTypes = {
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      category: PropTypes.string,
      formattedNumber: PropTypes.string,
      phoneNumber: PropTypes.string,
      notes: PropTypes.string,
      primary: PropTypes.bool,
      active: PropTypes.bool,
    })
  ),
  personId: PropTypes.string.isRequired,
  personType: PropTypes.string.isRequired,
  csrfToken: PropTypes.string,
  displayEdit: PropTypes.bool,
  currentUrl: PropTypes.string,
  phoneNumberEditLink: PropTypes.string,
  showPhoneNumberEditLink: PropTypes.bool,
};

export default PhoneNumbers;
