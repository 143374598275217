import { useQuery } from "@apollo/client";
import { Button, InputHidden } from "@heart/components";
import PropTypes from "prop-types";

import MutationForm from "@components/MutationForm";

import MarkPhoneNumberActivity from "@graphql/mutations/MarkPhoneNumberActivity.graphql";
import MarkPhoneNumberAsPrimary from "@graphql/mutations/MarkPhoneNumberAsPrimary.graphql";
import PhoneNumbersByUser from "@graphql/queries/PhoneNumbersByUser";

import ItemListTable from "../reusable_ui/ItemListTable";

const PhoneNumberList = ({
  csrfToken,
  currentUrl,
  personId,
  personType,
  phoneNumbers,
}) => {
  const { data, refetch } = useQuery(PhoneNumbersByUser, {
    variables: { personType, personId },
  });

  let latestPhoneNumbers = data ? data.phoneNumbers : phoneNumbers;
  const sortOrder = phoneNumber => {
    if (phoneNumber.primary) {
      return -1;
    } else if (!phoneNumber.active) {
      return 1;
    }

    return 0;
  };

  latestPhoneNumbers = [...latestPhoneNumbers].sort(
    (a, b) => sortOrder(a) - sortOrder(b) || a.id - b.id
  );

  const primaryStatus = phoneNumber => {
    if (phoneNumber.active && phoneNumber.primary) {
      return "primary";
    }
    if (phoneNumber.active) {
      return (
        <MutationForm
          mutation={MarkPhoneNumberAsPrimary}
          csrfToken={csrfToken}
          failureUrl={currentUrl}
          successUrl={currentUrl}
          onSuccess={handleSuccess}
        >
          <InputHidden name="id" value={phoneNumber.id} />
          <Button variant="secondary" type="submit">
            mark as primary
          </Button>
        </MutationForm>
      );
    }
    return "(inactive)";
  };

  const handleSuccess = () => {
    refetch();
  };

  const phoneNumberRows = latestPhoneNumbers.map(
    phoneNumber => (
      <tr key={phoneNumber.id}>
        <td>
          <If condition={phoneNumber.active}>
            <a href={`tel:${phoneNumber.phoneNumber}`}>
              {phoneNumber.formattedNumber}
            </a>
          </If>
          <If condition={!phoneNumber.active}>{phoneNumber.formattedNumber}</If>

          <p className="inline-hint">{phoneNumber.notes}</p>
        </td>
        <td>{phoneNumber.category}</td>
        <td>{primaryStatus(phoneNumber)}</td>
        <td>
          <MutationForm
            mutation={MarkPhoneNumberActivity}
            csrfToken={csrfToken}
            failureUrl={currentUrl}
            successUrl={currentUrl}
            onSuccess={handleSuccess}
          >
            <InputHidden name="id" value={phoneNumber.id} />
            <InputHidden
              name="active"
              value={(!phoneNumber.active).toString()}
            />
            <Button variant="secondary" type="submit">
              {phoneNumber.active ? "mark inactive" : "mark as active"}
            </Button>
          </MutationForm>
        </td>
      </tr>
    ),
    this
  );

  return (
    <ItemListTable
      headers={[
        "Number",
        "Phone Category",
        "Change Primary Status",
        "Inactive Status",
      ]}
    >
      {phoneNumberRows}
    </ItemListTable>
  );
};

PhoneNumberList.propTypes = {
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      category: PropTypes.string,
      formattedNumber: PropTypes.string,
      phoneNumber: PropTypes.string,
      notes: PropTypes.string,
      primary: PropTypes.bool,
      active: PropTypes.bool,
    })
  ),
  personId: PropTypes.string.isRequired,
  personType: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
};

export default PhoneNumberList;
