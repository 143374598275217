import {
  Button,
  Icons,
  InputText,
  InputPhone,
  InputDropdown,
  InputHidden,
} from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import MutationForm from "@components/MutationForm";

import UpdatePhoneNumbers from "@graphql/mutations/UpdatePhoneNumbers.graphql";

import ItemListTable from "../reusable_ui/ItemListTable";

const PhoneNumberRow = ({ phoneNumber, index, personType, personId }) => {
  const replaceNullWithEmptyString = value => (value === null ? "" : value);
  const newPhoneNumber = !phoneNumber.id;
  const [deleted, setDeleted] = useState(false);

  if (deleted) {
    return null;
  }

  return (
    <tr data-testid="phone-row">
      <td className="min-required-width">
        <InputPhone
          label="Phone number"
          name={`phoneNumbers[${index}][phoneNumber]`}
          value={phoneNumber.phoneNumber}
          required={true}
        />
      </td>
      <td className="min-required-width">
        <If condition={!newPhoneNumber}>
          <InputHidden
            name={`phoneNumbers[${index}][id]`}
            value={replaceNullWithEmptyString(phoneNumber.id)}
          />
        </If>
        <InputHidden
          name={`phoneNumbers[${index}][personType]`}
          value={personType}
        />
        <InputHidden
          name={`phoneNumbers[${index}][personId]`}
          value={personId}
        />
        <InputDropdown
          label="Type"
          name={`phoneNumbers[${index}][category]`}
          values={["mobile", "home", "work"]}
          value={replaceNullWithEmptyString(phoneNumber.category)}
        />
      </td>
      <td>
        <InputText
          label="Notes"
          id={`notes-${phoneNumber.id}`}
          type="text"
          name={`phoneNumbers[${index}][notes]`}
          value={replaceNullWithEmptyString(phoneNumber.notes)}
          previousValue=""
        />
      </td>
      <td>
        <If condition={newPhoneNumber}>
          <div className="space-above-4">
            <Button
              variant="secondary"
              onClick={() => {
                setDeleted(true);
              }}
            >
              Remove
            </Button>
          </div>
        </If>
      </td>
    </tr>
  );
};

/** Edit or delete applicants' phone numbers (and associated info) without leaving the page.
 *    Applicant Data > Phone Number row > Edit
 */
const PhoneNumberInlineEdit = ({
  phoneNumbers,
  csrfToken,
  currentUrl,
  personType,
  personId,
  phoneNumberEditLink,
  handleSuccess,
  handleCancel,
}) => {
  const emptyPhoneNumber = {
    id: "",
    category: "",
    phoneNumber: "",
    notes: "",
    personType: "",
    personId: "",
  };

  const phoneWithOne =
    phoneNumbers.length === 0 ? [emptyPhoneNumber] : phoneNumbers;
  const [dynamicPhoneNumbers, setDynamicPhoneNumbers] = useState(phoneWithOne);
  const [phoneAddedIndex, setPhoneAddedIndex] = useState(0);

  const addEmptyPhoneNumber = () => {
    setPhoneAddedIndex(phoneAddedIndex + 1);
    setDynamicPhoneNumbers(phoneNumbersMap => [
      ...phoneNumbersMap,
      { ...emptyPhoneNumber },
    ]);
  };

  return (
    <MutationForm
      mutation={UpdatePhoneNumbers}
      csrfToken={csrfToken}
      failureUrl={`${currentUrl}?${phoneNumberEditLink}=true`}
      successUrl={currentUrl}
      onSuccess={handleSuccess}
    >
      <ItemListTable
        headers={[
          "Phone Category",
          "Phone Number",
          "Change Primary Status",
          "Inactive Status",
        ]}
      >
        {dynamicPhoneNumbers.map((phoneNumber, index) => (
          <PhoneNumberRow
            phoneNumber={phoneNumber}
            index={index}
            personType={personType}
            personId={personId}
            key={`phone-number-row-${index}`}
          />
        ))}
      </ItemListTable>
      <Button
        variant="secondary"
        onClick={addEmptyPhoneNumber}
        icon={Icons.Plus}
      >
        Add Another Contact Number
      </Button>
      <div className="space-above-1">
        <Button type="submit">Save Changes</Button>
        <a href={currentUrl} onClick={handleCancel} className="indent-left-1">
          Cancel
        </a>
      </div>
    </MutationForm>
  );
};

PhoneNumberRow.propTypes = {
  phoneNumber: PropTypes.shape({
    id: PropTypes.string.isRequired,
    category: PropTypes.string,
    formattedNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    notes: PropTypes.string,
    primary: PropTypes.bool,
    active: PropTypes.bool,
  }),
  index: PropTypes.number.isRequired,
  personType: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
};

PhoneNumberInlineEdit.propTypes = {
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      category: PropTypes.string,
      formattedNumber: PropTypes.string,
      phoneNumber: PropTypes.string,
      notes: PropTypes.string,
      primary: PropTypes.bool,
      active: PropTypes.bool,
    })
  ),
  personType: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  csrfToken: PropTypes.string,
  currentUrl: PropTypes.string,
  phoneNumberEditLink: PropTypes.string,
  handleSuccess: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default PhoneNumberInlineEdit;
