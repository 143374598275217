import { calculateProgress } from "@heart/components/wizard/Wizard";
import { curry, intersection, isEmpty } from "lodash";

import { translationWithRoot } from "@components/T";

import { formatAsLongDate } from "@lib/dates";

import {
  WILLING,
  UNWILLING,
  PROHIBITED,
  NOT_APPLICABLE,
} from "@root/constants";

const { t } = translationWithRoot("agency_human.form");

export const valuesFromResponse = curry((childAgencyHumanId, response) => {
  const values = [{ label: t("create_new_person"), value: "new_agency_human" }];

  (response?.agencyHumanMatches || []).forEach(
    ({ id, fullName, dateOfBirth, ...rest }) => {
      if (id !== childAgencyHumanId) {
        values.push({
          /** Display the Agency Human's name and date of birth if known in the dropdown
           * of search results for potential matches
           */
          label: t("search_result_with_dob", {
            name: fullName,
            dob: dateOfBirth ? formatAsLongDate(dateOfBirth) : t("unknown"),
          }),
          value: id,
          id,
          fullName,
          dateOfBirth,
          ...rest,
        });
      }
    }
  );

  return values;
});

export const calculateAgencyHumanPercentage = ({
  agencyHuman,
  firstName,
  askedAboutProtectedTribe,
  tribes,
}) => {
  let requiredFields = [agencyHuman, firstName];
  if (askedAboutProtectedTribe)
    requiredFields = [
      ...requiredFields,
      tribes?.isProtectedTribeMember !== undefined,
    ];
  return calculateProgress({ requiredFields });
};

export const agencyHumanErrors = {
  primaryAndInactiveError: ({
    entity: { primary, inactive },
    translationPrefix,
  }) =>
    primary && inactive ? t(`${translationPrefix}.inactive_primary_error`) : "",

  primaryCountError: ({ entities, translationPrefix }) => {
    if (isEmpty(entities)) return "";

    const primaryCount = entities.reduce(
      (count, { primary }) => (primary ? count + 1 : count),
      0
    );

    if (primaryCount > 1)
      return t(`${translationPrefix}.multiple_primary_error`);
    return "";
  },

  primaryAddressesError: ({ addresses = [] }) =>
    agencyHumanErrors.primaryCountError({
      entities: addresses.map(({ address }) => address),
      translationPrefix: "addresses",
    }),

  primaryEmailAddressesError: ({ emailAddresses = [] }) =>
    agencyHumanErrors.primaryCountError({
      entities: emailAddresses,
      translationPrefix: "email_address",
    }),

  primaryPhoneNumbersError: ({ phoneNumbers = [] }) =>
    agencyHumanErrors.primaryCountError({
      entities: phoneNumbers,
      translationPrefix: "phone_number",
    }),
};

export const agencyHumanInvalid = ({
  formState: { addresses = [], emailAddresses = [], phoneNumbers = [] },
  ethnicitiesValid,
}) => {
  if (!ethnicitiesValid) return true;

  /** Invalid if primary and inactive are both selected for any entry */
  const noPrimaryInactiveAddressErrors = addresses.every(({ address }) =>
    isEmpty(
      agencyHumanErrors.primaryAndInactiveError({
        entity: address,
        translationPrefix: "addresses",
      })
    )
  );
  if (!noPrimaryInactiveAddressErrors) return true;

  const noPrimaryInactiveEmailErrors = emailAddresses.every(email =>
    isEmpty(
      agencyHumanErrors.primaryAndInactiveError({
        entity: email,
        translationPrefix: "email_address",
      })
    )
  );
  if (!noPrimaryInactiveEmailErrors) return true;

  /** The phone number model uses an "active" attribute, but we convert
   * to "inactive" after loading them for consistency with emails and addresses
   */
  const noPrimaryInactivePhoneErrors = phoneNumbers.every(phone =>
    isEmpty(
      agencyHumanErrors.primaryAndInactiveError({
        entity: phone,
        translationPrefix: "phone_number",
      })
    )
  );
  if (!noPrimaryInactivePhoneErrors) return true;

  /** Invalid if there is an error for addresses */
  const primaryAddressError = agencyHumanErrors.primaryAddressesError({
    addresses,
  });
  if (!isEmpty(primaryAddressError)) return true;

  /** Invalid if there is an error for email addresses */
  const primaryEmailError = agencyHumanErrors.primaryEmailAddressesError({
    emailAddresses,
  });
  if (!isEmpty(primaryEmailError)) return true;

  /** Invalid if there is an error for phone numbers */
  const primaryPhoneError = agencyHumanErrors.primaryPhoneNumbersError({
    phoneNumbers,
  });
  if (!isEmpty(primaryPhoneError)) return true;

  return false;
};

export const relationshipErrors = {
  getPlacementEligibilitiesError: ({ placementProviderEligibilities = [] }) => {
    let placementEligibilitiesError = "";
    if (placementProviderEligibilities.length > 1) {
      if (placementProviderEligibilities.includes(PROHIBITED)) {
        placementEligibilitiesError = t(
          "relationship_to_child.prohibited_cannot_be_selected_with_other_options"
        );
      } else if (placementProviderEligibilities.includes(NOT_APPLICABLE)) {
        placementEligibilitiesError = t(
          "relationship_to_child.not_applicable_cannot_be_selected_with_other_options"
        );
      } else if (
        intersection([WILLING, UNWILLING], placementProviderEligibilities)
          .length > 1
      ) {
        placementEligibilitiesError = t(
          "relationship_to_child.willing_and_unwilling_are_mutually_exclusive"
        );
      }
    }
    return placementEligibilitiesError;
  },

  getParentalLineError: ({ parentalLine = [] }) =>
    parentalLine.length > 2
      ? t("relationship_to_child.please_select_two_or_fewer")
      : "",
};

export const relationshipsInvalid = ({ relationships = [] }) => {
  if (isEmpty(relationships)) return false;
  return !relationships.every(
    ({ placementProviderEligibilities = [], parentalLine = [] } = {}) =>
      isEmpty(
        relationshipErrors.getPlacementEligibilitiesError({
          placementProviderEligibilities,
        })
      ) && isEmpty(relationshipErrors.getParentalLineError({ parentalLine }))
  );
};
