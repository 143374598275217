/*
 * Roughly, this component is a dropdown that asks whether the
 * number of other adults in the household is a known number.
 * If the dropdown is set to yes, it displays an OtherAdultForm component
 * and uses the setOtherAdultsInHomeKnown graphql mutation to
 * properly update the models on the backend. Otherwise it waits
 * with a message telling users to collect this data.
 *
 * Applicant Data > Other Adults (in readonly mode)
 * Intake Family > Other Adults
 */
import { Button, Text, Flex, InputDropdown } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import React, { useState, useEffect, Fragment } from "react";
import { useBeforeunload } from "react-beforeunload";

import generateId from "@lib/generateId";

import OtherAdultsForm from "./OtherAdultsForm";
import styles from "./OtherAdultsSection.module.scss";
import useOtherAdultsInHomeKnown from "./useOtherAdultsInHomeKnown";

const allOptions = [
  {
    value: "yes",
    label: I18n.t(
      "views.applications.application_other_adult.known_other_adult_selector.yes_answer"
    ),
  },
  {
    value: "no",
    label: I18n.t(
      "views.applications.application_other_adult.known_other_adult_selector.no_answer"
    ),
  },
  {
    value: "not_known",
    label: I18n.t(
      "views.applications.application_other_adult.known_other_adult_selector.not_known"
    ),
  },
];
const generateOptions = otherAdultsInHomeCount => {
  const options = [allOptions[0]];

  if (otherAdultsInHomeCount === 0) {
    options.push(allOptions[1]);
    options.push(allOptions[2]);
  }
  return options;
};
const OtherAdultsSection = ({
  agencyId,
  applicationId,
  helpText,
  otherAdultTypes,
  placementProviderId,
  sectionHelpText,
  nextPageLinkPath,
  nextPageLinkText,
  unknownNumberPlaceholderText = I18n.t(
    "views.applications.application_other_adult.unknown_placeholder"
  ),
  readOnly = false,
  exposeDateOfBirth = false,
  canRemoveReadOnlyAdult,
}) => {
  const {
    loading,
    otherAdultsInHomeKnown,
    otherAdultsInHomeCount,
    setOtherAdultsInHomeKnown,
  } = useOtherAdultsInHomeKnown(applicationId);
  const [otherAdultsBeingEdited, setOtherAdultsBeingEdited] = useState(false);
  const [addingNewAdult, setAddingNewAdult] = useState(false);

  const [otherAdultsKnownOptionSelected, setOtherAdultsKnownOptionSelected] =
    useState("not_known");
  const [
    manuallySetOtherAdultsKnownOptionSelected,
    setManuallySetotherAdultsKnownOptionSelected,
  ] = useState(false);
  useEffect(() => {
    if (loading || manuallySetOtherAdultsKnownOptionSelected) {
      return;
    }
    if (otherAdultsInHomeKnown && otherAdultsInHomeCount > 0) {
      setOtherAdultsKnownOptionSelected("yes");
    } else if (otherAdultsInHomeKnown) {
      setOtherAdultsKnownOptionSelected("no");
    }
  }, [
    loading,
    otherAdultsInHomeKnown,
    otherAdultsInHomeCount,
    manuallySetOtherAdultsKnownOptionSelected,
  ]);
  const [dropdownId] = useState(generateId());
  useBeforeunload(() => {
    if (
      otherAdultsKnownOptionSelected === "yes" &&
      otherAdultsInHomeCount === 0
    ) {
      // any non-null string causes the "your changes may not be saved" message.
      return "";
    }
    return null;
  });
  return (
    <section className={styles.normal}>
      <div className={styles.borderSpacing}>
        <Text as="h2" textStyle="emphasis-100">
          {I18n.t("views.applications.application_other_adult.section_title")}
          {` (${I18n.t("application.required_for_approval")})`}
        </Text>
        <Text as="div" textStyle="supporting-100" textColor="neutral-500">
          {sectionHelpText}
        </Text>
        {loading ? (
          <Fragment />
        ) : (
          <Fragment>
            <If condition={!readOnly}>
              <InputDropdown
                label={I18n.t(
                  "views.applications.application_other_adult.known_other_adult_selector.label"
                )}
                hideBlank
                id={dropdownId}
                onChange={option => {
                  setOtherAdultsInHomeKnown(
                    option === "yes" || option === "no"
                  );
                  setOtherAdultsKnownOptionSelected(option);
                  setManuallySetotherAdultsKnownOptionSelected(true);
                }}
                values={generateOptions(otherAdultsInHomeCount)}
                blankOptionText={I18n.t(
                  "views.applications.application_other_adult.known_other_adult_selector.placeholder"
                )}
                value={otherAdultsKnownOptionSelected}
              />
            </If>
            <If condition={otherAdultsInHomeKnown}>
              <OtherAdultsForm
                agencyId={agencyId}
                applicationId={applicationId}
                helpText={helpText}
                otherAdultTypes={otherAdultTypes}
                placementProviderId={placementProviderId}
                readOnly={readOnly}
                setEditingCallback={editingObj => {
                  // If any OtherAdults are being edited, don't allow submitting this page.
                  setOtherAdultsBeingEdited(
                    Object.values(editingObj).includes(true)
                  );
                }}
                setAddingNewAdultCallback={setAddingNewAdult}
                exposeDateOfBirth={exposeDateOfBirth}
                canRemoveReadOnlyAdult={canRemoveReadOnlyAdult}
                showListAnotherHouseholdMember={
                  otherAdultsKnownOptionSelected === "yes"
                }
              />
            </If>
            <If condition={!otherAdultsInHomeKnown}>
              <Text>{unknownNumberPlaceholderText}</Text>
            </If>
          </Fragment>
        )}
      </div>
      <div className={styles.borderSpacing}>
        {loading ? (
          <Fragment />
        ) : (
          <If condition={nextPageLinkPath}>
            <Flex row gap="300" align="center">
              <Button
                description={nextPageLinkText}
                // Using onClick here instead of href because the `disabled` argument only
                // works when we're rendering a <button> under the hood, not an <a>
                onClick={() => {
                  window.location.href = nextPageLinkPath;
                }}
                disabled={
                  otherAdultsInHomeKnown &&
                  (addingNewAdult ||
                    otherAdultsBeingEdited ||
                    (otherAdultsKnownOptionSelected === "yes" &&
                      otherAdultsInHomeCount === 0))
                }
              >
                {nextPageLinkText}
              </Button>
              <If
                condition={
                  otherAdultsInHomeKnown &&
                  (addingNewAdult || otherAdultsBeingEdited)
                }
              >
                <Text textColor="danger-800" textStyle="emphasis-100">
                  {I18n.t(
                    "views.applications.application_other_adult.known_other_adult_selector.finish_editing"
                  )}
                </Text>
              </If>
            </Flex>
          </If>
        )}
      </div>
    </section>
  );
};

OtherAdultsSection.propTypes = {
  agencyId: PropTypes.number.isRequired,
  applicationId: PropTypes.number.isRequired,
  helpText: PropTypes.string,
  otherAdultTypes: PropTypes.array.isRequired,
  placementProviderId: PropTypes.number.isRequired,
  sectionHelpText: PropTypes.string.isRequired,
  nextPageLinkPath: PropTypes.string,
  nextPageLinkText: PropTypes.string,
  unknownNumberPlaceholderText: PropTypes.string,
  readOnly: PropTypes.bool,
  exposeDateOfBirth: PropTypes.bool,
  canRemoveReadOnlyAdult: PropTypes.bool,
};

export default OtherAdultsSection;
