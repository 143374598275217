import { gql, useQuery } from "@apollo/client";
import { Button } from "@heart/components";
import I18n from "i18n-js";
import { isEmpty, isNil } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";

import PlacementProviderForApplication from "@graphql/queries/PlacementProviderForApplication.graphql";

import MessageDisplay from "../MessageDisplay";
import determineUserAgencyProfile from "../determineUserAgencyProfile";
import useMessageDisplay from "../useMessageDisplay";
import OtherAdult from "./OtherAdult";
import OtherAdultEditor from "./OtherAdultEditor";

const OtherAdultsForm = ({
  agencyId,
  applicationId,
  helpText,
  otherAdultTypes,
  placementProviderId,
  readOnly,
  exposeDateOfBirth = false,
  setEditingCallback = () => {},
  setAddingNewAdultCallback = () => {},
  canRemoveReadOnlyAdult,
  showListAnotherHouseholdMember = true,
}) => {
  const { data: applicationData } = useQuery(
    gql`
      query Application($id: ID!) {
        application(id: $id) {
          id
          type
          statusDate
          status
          createdAt
          applicationOtherAdults {
            humanPlacementProviderRoleId
          }
        }
      }
    `,
    { variables: { id: applicationId } }
  );
  const app = applicationData?.application;

  // We will only show other adults for which we this application has
  // requirements, which is in turn indicated by whether or not there is a
  // corresponding ApplicationOtherAdult
  const aoaExistsForAdult = adult => {
    const hpprIDs = app
      ? app.applicationOtherAdults.map(aoa => aoa.humanPlacementProviderRoleId)
      : [];
    return hpprIDs.includes(adult.id);
  };

  const { data: placementProviderData, error: placementProviderError } =
    useQuery(PlacementProviderForApplication, {
      variables: { placementProviderId, applicationId },
    });

  const savedAdults = placementProviderData
    ? placementProviderData.placementProviderForApplication.otherAdultsInHome
    : [];
  const caregivers = placementProviderData
    ? placementProviderData.placementProviderForApplication.caregivers
    : [];

  /*
   * This is a map from emails to the user agency profile for that email.
   * existingUserAgencyProfiles is used for front end validation to determine whether
   *   the email provided when adding or editing an Other Adult is already in
   *   use by another member of the household. Without this check,
   *   the mutation will blow up on duplicates.
   * Users try to use the same email for multiple people in more cases than we might
   *   expect, customer support gets plenty of folks who have fewer emails than people
   *   per household
   */
  const existingUserAgencyProfiles = placementProviderData
    ? [...savedAdults, ...caregivers].reduce((obj, savedAdult) => {
        const profile = determineUserAgencyProfile({
          agencyId,
          person: savedAdult,
        });
        return {
          ...obj,
          [profile.email]: { ...profile, leftAt: savedAdult.leftAt },
        };
      }, {})
    : {};

  const messageDisplay = useMessageDisplay();
  const [addingNewAdult, setAddingNewAdultState] = useState(false);
  const setAddingNewAdult = arg => {
    setAddingNewAdultState(arg);
    setAddingNewAdultCallback(arg);
  };
  const [editing, setEditing] = useState({});
  /* localErrorMessages is an array that contains strings or JSX components
   * that are displayed in OtherAdultEditor component. We set state here so that
   * we can pass down global error states into this component.
   */
  const [localErrorMessages, setlocalErrorMessages] = useState([]);

  if (placementProviderError)
    return <pre>{placementProviderError.toString()}</pre>;
  if (!placementProviderData) return null;

  const setEditingForAdult = ({ id, value }) => {
    if (!isNil(id)) {
      setEditingCallback({ ...editing, [id]: value });
      setEditing({ ...editing, [id]: value });
    }
  };

  const disableEditJoinedAt = adult => {
    if (!app) {
      return true;
    }
    const appCreatedAt = DateTime.fromISO(app.createdAt);
    const adultJoinedAt = DateTime.fromISO(adult.joinedAt);
    // true disables datepicker
    return (
      adultJoinedAt < appCreatedAt.startOf("day") &&
      app.type === "RenewalApplication"
    );
  };

  return (
    <Fragment>
      <If condition={helpText}>
        <p className="space-above-2 space-below-2">{helpText}</p>
      </If>
      <div className="space-below-2" id="oa_error_banner">
        <MessageDisplay messageData={messageDisplay} />
      </div>
      <Fragment>
        {savedAdults
          .filter(aoaExistsForAdult)
          .sort(
            ({ joinedAt: joinedAtA }, { joinedAt: joinedAtB }) =>
              DateTime.fromISO(joinedAtA) - DateTime.fromISO(joinedAtB)
          )
          .map(adult =>
            editing[adult.id] ? (
              <OtherAdultEditor
                key={adult.id}
                // Model data
                agencyId={agencyId}
                applicationId={applicationId}
                placementProviderId={placementProviderId}
                existingAdult={adult}
                otherAdultTypes={otherAdultTypes}
                existingUserAgencyProfiles={existingUserAgencyProfiles}
                // Component configuration
                hasDateOfBirthInput={exposeDateOfBirth}
                disableEditJoinedAt={disableEditJoinedAt(adult)}
                // React state
                messageDisplay={messageDisplay}
                setAddingNewAdult={setAddingNewAdult}
                setEditingForAdult={value =>
                  setEditingForAdult({ id: adult.id, value })
                }
                localErrorMessages={localErrorMessages}
                setlocalErrorMessages={setlocalErrorMessages}
              />
            ) : (
              <OtherAdult
                key={adult.id}
                // Model data
                adult={adult}
                agencyId={agencyId}
                applicationId={applicationId}
                placementProviderId={placementProviderId}
                // Component configuration
                readOnly={readOnly}
                canRemoveReadOnlyAdult={canRemoveReadOnlyAdult}
                showDateOfBirth={exposeDateOfBirth}
                showSubrole={!isEmpty(otherAdultTypes)}
                // React state
                setEditingForAdult={value =>
                  setEditingForAdult({ id: adult.id, value })
                }
                messageDisplay={messageDisplay}
              />
            )
          )}
      </Fragment>
      <If condition={!readOnly}>
        {addingNewAdult ? (
          <OtherAdultEditor
            // Model data
            agencyId={agencyId}
            applicationId={applicationId}
            placementProviderId={placementProviderId}
            otherAdultTypes={otherAdultTypes}
            existingUserAgencyProfiles={existingUserAgencyProfiles}
            // Component configuration
            hasDateOfBirthInput={exposeDateOfBirth}
            // React state
            messageDisplay={messageDisplay}
            setAddingNewAdult={setAddingNewAdult}
            setEditingForAdult={value =>
              setEditingForAdult({ index: null, value })
            }
            localErrorMessages={localErrorMessages}
            setlocalErrorMessages={setlocalErrorMessages}
          />
        ) : (
          <If condition={showListAnotherHouseholdMember}>
            <Button variant="secondary" onClick={() => setAddingNewAdult(true)}>
              {I18n.t(
                "views.applications.application_other_adult.list_another_household_member"
              )}
            </Button>
          </If>
        )}
      </If>
    </Fragment>
  );
};

OtherAdultsForm.propTypes = {
  agencyId: PropTypes.number.isRequired,
  applicationId: PropTypes.number.isRequired,
  helpText: PropTypes.string,
  otherAdultTypes: PropTypes.array.isRequired,
  placementProviderId: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  canRemoveReadOnlyAdult: PropTypes.bool,
  /* Boolean flag as to whether to we want to show/capture date of birth,
   this varies depending on context of who is viewing the component.
   Defaults to true (see above)   */
  exposeDateOfBirth: PropTypes.bool,
  /* callback invoked whenever an other adult is edited. It's called with an object
   that has keys which are ids of other adults, and value is true/false.
  */
  setEditingCallback: PropTypes.func,
  /* Callback invoked whenever an other adult is added.
   The callback is invoked with a boolean argument */
  setAddingNewAdultCallback: PropTypes.func,
  showListAnotherHouseholdMember: PropTypes.bool,
};

export default OtherAdultsForm;
