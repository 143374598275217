/**
 * Conditionally show/hide sections to edit (made for agencies in admin/user)
 */

$(() => {
  const $userRole = $("#agency_worker_role");
  if ($userRole.length === 0) {
    return;
  }

  const $addAgencyBtn = $(
    ".edit.admin_agency_workers .has_many_add, " +
      ".new.admin_agency_workers .has_many_add"
  );
  const isBintiAdmin = $("input[name='is_binti_admin']").val() === "true";

  $(".agency_admin_assignments").on("has_many_add:after", assignmentAdded);

  $("#agency_worker_role").on("change", roleChanged);

  roleChanged();

  function roleChanged() {
    if ($userRole.val() === "caseworker") {
      $(".has_many_fields").show();
      displayAssignments();
    } else {
      $(".has_many_fields").hide();
      $addAgencyBtn.hide();
    }
  }

  function displayAssignments() {
    if ($(".inputs.has_many_fields").length >= 1) {
      $addAgencyBtn.hide();
      return;
    }

    $addAgencyBtn.click();
    $addAgencyBtn.hide();

    assignmentAdded();
  }

  function assignmentAdded() {
    // don't show the remove button for non-admin
    if (!isBintiAdmin) {
      $(".agency_admin_assignments .has_many_remove").hide();
    }
  }
});
