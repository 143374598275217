import { useQuery } from "@apollo/client";
import {
  DetailsTable,
  DisplaySSN,
  Flex,
  FlexItem,
  Icons,
  Link,
  Notice,
  Surface,
  Text,
} from "@heart/components";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";
import { determineName } from "@components/family_finding/relationships/sortRelationships";
import { translateLabel } from "@components/inputs/nested_multi/NestedMulti";

import AgencyHumanRelationships from "@graphql/queries/AgencyHumanRelationships.graphql";

import { formatAsLongDate } from "@lib/dates";

import styles from "./AgencyHumanSummary.module.scss";
import {
  constructIcwaDetails,
  constructAddressLabel,
  displayAddress,
  displayPhoneNumbers,
  displayEmails,
} from "./displayAgencyHumanInfo";

const { T, t } = translationWithRoot("agency_human.summary");

const AgencyHumanSummary = ({ agencyHuman = {} }) => {
  const {
    id,
    fullName,
    dateOfBirth,
    isDeceased,
    dateOfDeath,
    deathDetails,
    ssn,
    gender,
    ethnicities,
    socialMediaLinks = [],
    childPhoneNumber,
    addresses = [],
    emailAddresses = [],
    phoneNumbers = [],
    riskFlags = [],
    confidential,
  } = agencyHuman;

  const { data: relationshipData } = useQuery(AgencyHumanRelationships, {
    variables: { agencyHumanId: id, filterForChildrenInCare: true },
    skip: isEmpty(id),
  });

  return (
    <Fragment>
      <If condition={confidential}>
        <Notice
          title={
            <Flex>
              <Icons.ExclamationTriangle />
              <T t="confidential_warning" />
            </Flex>
          }
        />
      </If>
      <Surface title={t("personal_info")}>
        <DetailsTable
          details={[
            { label: t("name"), value: fullName },
            { label: t("gender"), value: gender },
            {
              label: t("date_of_birth"),
              value: dateOfBirth ? formatAsLongDate(dateOfBirth) : "",
            },
            isDeceased && {
              label: t("is_deceased"),
              value: I18n.t(
                `javascript.components.common.${isDeceased ? "yes" : "no"}`
              ),
            },
            isDeceased && {
              label: t("date_of_death"),
              value: dateOfDeath ? formatAsLongDate(dateOfDeath) : "",
            },
            isDeceased && { label: t("death_details"), value: deathDetails },
            { label: t("ssn"), value: <DisplaySSN ssn={ssn} /> },
            {
              label: t("related_children"),
              value: (relationshipData?.agencyHumanRelationships || []).map(
                ({ sourceAgencyHuman, destinationAgencyHuman }, index) => {
                  const { href, name } = determineName({
                    keystoneAgencyHumanId: id,
                    sourceAgencyHuman,
                    destinationAgencyHuman,
                  });
                  return (
                    <Fragment key={name}>
                      <If condition={index > 0}>{" | "}</If>
                      <Link href={href}>{name}</Link>
                    </Fragment>
                  );
                }
              ),
            },
          ].filter(Boolean)}
        />
      </Surface>
      <Surface title={t("ethnicity_and_icwa_information")} hideTitle>
        <DetailsTable
          caption={t("ethnicity")}
          details={[
            {
              label: t("ethnicities"),
              value: Object.keys(ethnicities || {})
                .map(ethnicity =>
                  translateLabel({
                    i18nKey: `activerecord.enums.common.ethnicities.${ethnicity}`,
                  })
                )
                .join(", "),
            },
          ]}
        />
        <DetailsTable
          caption={t("icwa_information")}
          details={constructIcwaDetails(agencyHuman)}
        />
      </Surface>
      <Surface title={t("addresses")}>
        <DetailsTable
          details={addresses.map(address => ({
            label: constructAddressLabel(address),
            value: displayAddress(address),
          }))}
        />
      </Surface>
      <Surface title={t("contact_info")}>
        <Text textStyle="emphasis-200" className={styles.listTitle}>
          {t("phone_numbers")}
        </Text>
        <span className={styles.list}>
          {displayPhoneNumbers({ phoneNumbers, childPhoneNumber })}
        </span>
        <Text textStyle="emphasis-200" className={styles.listTitle}>
          {t("email_addresses")}
        </Text>
        <span className={styles.list}>{displayEmails({ emailAddresses })}</span>
      </Surface>
      <Surface title={t("social_media")}>
        <Flex as="ul" column>
          {socialMediaLinks.map(link => (
            <FlexItem as="li" key={link} className={styles.list}>
              {link}
            </FlexItem>
          ))}
        </Flex>
      </Surface>
      <If condition={!isEmpty(riskFlags)}>
        <Surface title={t("risk_flags")}>
          {t("risk_flags_disclaimer")}
          <Flex as="ul" column>
            {riskFlags.map(flag => (
              <FlexItem as="li" key={flag} className={styles.list}>
                {flag}
              </FlexItem>
            ))}
          </Flex>
        </Surface>
      </If>
    </Fragment>
  );
};
AgencyHumanSummary.propTypes = {
  agencyHuman: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    ssn: PropTypes.string,
    gender: PropTypes.string,
    ethnicities: PropTypes.object,
    protectedTribeMember: PropTypes.bool,
    protectedTribeNames: PropTypes.arrayOf(PropTypes.string),
    protectedTribeIdentification: PropTypes.string,
  }),
};

export default AgencyHumanSummary;
