import { useDebouncedCallback } from "@react-hookz/web";
import PropTypes from "prop-types";
import { Suspense, useRef, useState, lazy } from "react";

import Label from "@components/reusable_ui/forms/Label";

import { register } from "@lib/QuillFileUploader";
import QuillLabelledBy from "@lib/QuillLabelledBy";
import QuillTestId from "@lib/QuillTestId";
import { useGeneratedIds } from "@lib/generateId";

import "./Notes.module.scss";

const ReactQuill = lazy(() =>
  Promise.all([
    import("react-quill"),
    import("quill-magic-url"),
    import("react-quill/dist/quill.snow.css"),
  ]).then(([ReactQuillModule, { default: MagicUrl }]) => {
    const { Quill } = ReactQuillModule;
    register(Quill);
    Quill.register("modules/labelledBy", QuillLabelledBy);
    Quill.register("modules/testId", QuillTestId);
    Quill.register("modules/magicUrl", MagicUrl);
    return ReactQuillModule;
  })
);

const Notes = ({ log, createOrUpdateLog }) => {
  const quillRef = useRef();
  const [notesId, labelId] = useGeneratedIds(3);
  /** this isn't super elegant but when our notes come back from the backend
   * we're losing the newline characters because the whole string gets wrapped
   * in paragraph tags
   *
   * we could preserve the newlines on the backend by saving the paragraph tags,
   * but they end up looking strange in the application history because they don't
   * get translated to html in the table. I also tried using line break tags, but
   * quill doesn't recognize them as newlines
   */
  const [htmlNotes, setHtmlNotes] = useState(
    `<p>${(log?.notes || "").replace(/\n/gim, "</p><p>")}</p>`
  );

  const notesChanged = useDebouncedCallback(
    value => {
      const strippedValue = value
        .replace(/<\/p><p>/gi, "\n")
        .replace(/(<([^>]+)>)/gi, "")
        /** When passed back up to the frontend, multiple newline tags don't end up rendering
         * as multiple line breaks. To avoid getting duplicate entries in the Applicant Data
         * history for just changing empty space, we're condensing multiple newlines down to
         * a single newline character
         */
        .replace(/[\n]+/, "\n");
      if (strippedValue === log.notes) return;
      createOrUpdateLog({
        ...log,
        /** Replacing paragraph & break tags with newlines so that we preserve the empty space of the notes.
         * Also removing html styling before sending the notes to our database so that we can display
         * them as plain text in CSVs and the list of changes in our application history
         */
        notes: strippedValue,
      });
    },
    [log],
    500
  );

  return (
    <Suspense fallback="Loading">
      <Label hide id={labelId} htmlFor={notesId}>
        {`${log.background_check} Notes`}
      </Label>
      <ReactQuill
        id={notesId}
        modules={{
          toolbar: ["link"],
          testId: {},
          labelledBy: { id: labelId },
          magicUrl: true,
        }}
        onChange={value => {
          setHtmlNotes(value);
          notesChanged(value);
        }}
        theme="snow"
        value={htmlNotes}
        readOnly={!log.current_user_can_edit}
        ref={quillRef}
      />
    </Suspense>
  );
};
Notes.propTypes = {
  log: PropTypes.shape({
    background_check: PropTypes.string.isRequired,
    current_user_can_edit: PropTypes.bool.isRequired,
    notes: PropTypes.string,
  }).isRequired,
  createOrUpdateLog: PropTypes.func.isRequired,
};

export default Notes;
