import PropTypes from "prop-types";

/** These are the props that can be found on every input. */
export default {
  /** Optional text to help the user understand how to complete this input */
  description: PropTypes.node,
  /** Callback to be invoked when the value changes */
  onChange: PropTypes.func,
  /** The input's `id` HTML attribute.  Use sparingly; if omitted one will be generated. */
  id: PropTypes.string,
  /** Label for this input */
  label: props => {
    if (!props.label && !props.labeledExternally) {
      return new Error(
        "The `label` prop is required.  To hide the label, use the `hideLabel` prop. " +
          "If you have provided a label for this component elsewhere on the page, use the " +
          "`labeledExternally` prop to indicate so."
      );
    }
    return undefined;
  },
  labeledExternally: props => {
    if (props.labeledExternally && !props.id) {
      return new Error(
        "The `id` prop is required when using an external label."
      );
    }
    return undefined;
  },
  /** HTML input name attribute */
  name: PropTypes.string,
  /** To indicate if this input is required */
  required: PropTypes.bool,
  /** Disable the input, preventing user interaction.
   *
   * **Note**: When you disable an input, its value will not be sent along with form POSTs!
   */
  disabled: PropTypes.bool,
  /** To indicate if this input should be hidden entirely but still present in the DOM */
  hidden: PropTypes.bool,
  /** Optional error text that will be displayed to the user */
  error: PropTypes.string,
  /** For inputs in a column, fullWidth indicates they should visually span the
   * entire column */
  fullWidth: PropTypes.bool,
};

export const inputGroupCommonPropTypes = {
  /** To indicate orientation of the options */
  orientation: PropTypes.oneOf(["column", "row"]),
};

export const textualInputPropTypes = {
  /** The initial value (uncontrolled) of this input, or the current value (controlled). */
  value: PropTypes.string,
  /** `onChange` is invoked with the text value as the only argument */
  onChange: PropTypes.func,
  /** `onBlur` is invoked with the event as the only argument */
  onBlur: PropTypes.func,
  /** A sample value placeholder for users to see what kind of input we expect */
  placeholder: PropTypes.string,
  /** The maximum number of characters a user can enter before this field is invalid */
  maxCharacters: PropTypes.number,
};
