import {
  Breadcrumbs,
  ContentTabs,
  Layout,
  PageContainer,
  Surface,
} from "@heart/components";
import PropTypes from "prop-types";
import {
  adminChildrenPath,
  downloadRelationshipsCsvPath,
  newPersonAsChildConnectionPath,
} from "routes";

import T, { translationWithRoot } from "@components/T";
import RelationshipsTable from "@components/family_finding/relationships/RelationshipsTable";
import AgencyServiceReferralsTab from "@components/prevention/profile/tabs/AgencyServiceReferralsTab";

import useFeatureFlag from "@lib/useFeatureFlag";

const { t } = translationWithRoot("prevention.profile_page");

const ProfilePage = ({ childId, agencyHumanId, childFullName }) => {
  const { flag: ffAddPotentialConnections012024 } = useFeatureFlag(
    "ff_add_potential_connections_01_2024"
  );

  const content = (
    <PageContainer fullWidth>
      <Surface hideTitle title={t("header", { full_name: childFullName })}>
        <ContentTabs
          tabs={[
            {
              title: t("tabs.relationships"),
              contents: (
                <RelationshipsTable
                  tab="relationships"
                  addPersonFormPath={newPersonAsChildConnectionPath(childId)}
                  keystoneAgencyHumanId={agencyHumanId.toString()}
                  downloadRelationshipsCsvPath={downloadRelationshipsCsvPath(
                    agencyHumanId
                  )}
                  ffAddPotentialConnections012024={
                    ffAddPotentialConnections012024
                  }
                />
              ),
            },
            {
              title: t("tabs.agency_service_referrals"),
              contents: (
                <AgencyServiceReferralsTab receivingHumanId={agencyHumanId} />
              ),
            },
            {
              title: t("tabs.plan_information"),
              contents: <h2>TODO Plan Information</h2>,
            },
            {
              title: t("tabs.documents"),
              contents: <h2>TODO Documents and Attachments</h2>,
            },
          ]}
        />
      </Surface>
    </PageContainer>
  );

  return (
    <Layout
      breadcrumbs={
        <Breadcrumbs
          pages={[
            {
              href: adminChildrenPath(),
              label: <T t={"prevention.breadcrumbs.home"} />,
            },
            { href: "#", label: childFullName },
          ]}
        />
      }
      main={{ content }}
    />
  );
};

ProfilePage.propTypes = {
  childId: PropTypes.number.isRequired,
  agencyHumanId: PropTypes.number.isRequired,
  childFullName: PropTypes.string.isRequired,
};

export default ProfilePage;
