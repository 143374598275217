import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import AttachmentControl from "@components/requirements/AttachmentControl";
import { Td, Tr } from "@components/reusable_ui/Table";

import DeleteApplicationAttachment from "@graphql/mutations/DeleteApplicationAttachment.graphql";

import { policy } from "@lib/graphqlHelpers";

const UnmatchedApplicationAttachmentRow = ({
  record: attachment,
  application,
  aroundLoader,
}) => {
  const [deleteApplicationAttachment] = useMutation(
    DeleteApplicationAttachment
  );

  const deleteAttachment = aroundLoader(() =>
    deleteApplicationAttachment({
      variables: {
        attachmentId: attachment.id,
        applicationId: application.id,
      },
    })
  );

  const mayDownloadAttachment = policy(attachment).mayShow();
  const mayDestroyAttachment = policy(attachment).mayDestroy();

  return (
    <Tr>
      <Td>
        <AttachmentControl
          {...{
            attachment,
            deleteAttachment,
            mayDestroyAttachment,
            mayDownloadAttachment,
          }}
        />
      </Td>
    </Tr>
  );
};

UnmatchedApplicationAttachmentRow.propTypes = {
  application: PropTypes.object.isRequired,
  aroundLoader: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default UnmatchedApplicationAttachmentRow;
