import { Background } from "@heart/components";
import PropTypes from "prop-types";

import styles from "./Pill.module.scss";

/**
 * Note: To display a status in Rails with the Pill component, you can use
 * `h.status_label` (`GlobalHelper#status_label`). `status_label` takes in:
 *  * `status`: the database value for the status that will be displayed
 *  * `status_map`: a map of status values to Pill variants (where the keys are
 *    all possible status values and the values are the corresponding Pill variants)
 *  * `text`: the display text for the status.
 *
 * Example:
 *
 * ```json
 *  h.status_label(
 *   status: record.status,
 *   status_map: {
 *     ready: :success,
 *     awaiting_response: :info,
 *     error: :warning,
 *     archived: :neutral,
 *   },
 *   text: I18n.t("some.path.#{record.status}"),
 *  )
 *```
 */

const Pill = ({ text, variant = "neutral" }) => {
  const backgroundColor = {
    alert: "alert-100",
    neutral: "info-50",
    info: "info-100",
    success: "success-100",
    warning: "warning-100",
  };

  return (
    <Background
      as="span"
      color={backgroundColor[variant]}
      className={styles.pill}
    >
      {text}
    </Background>
  );
};
Pill.propTypes = {
  /** Which variant? */
  variant: PropTypes.oneOf(["alert", "neutral", "info", "success", "warning"])
    .isRequired,
  /** What should the pill say? */
  text: PropTypes.string.isRequired,
};

export default Pill;
