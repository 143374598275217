import { IconMenu, Icons, Text } from "@heart/components";
import _ from "lodash";
import {
  agencyServicePath,
  newPreventionAgencyServiceReferralPath,
} from "routes";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "prevention.agency_services.dashboard.columns"
);
const ProviderNameColumn = {
  columnName: { name: t("provider_name") },
  cell: data => (
    <div>
      <Text textStyle="body-200">{data.providerAgency.name}</Text>
      <br />
      <Text textStyle="supporting-100" textColor="neutral-600">
        ID: {data.providerAgency.id}
      </Text>
    </div>
  ),
  id: "providerName",
  sortBy: "provider_agencies.name",
};

const ServiceNameColumn = {
  columnName: { name: t("service_name") },
  cell: data => data.agencyService.name,
  id: "serviceName",
  sortBy: "agency_services.name",
};

const ServiceRateNameColumn = {
  columnName: { name: t("service_rate_name") },
  cell: data => data.label,
  id: "serviceRateName",
  sortBy: "label",
};

const ActionsColumn = {
  columnName: { name: t("actions"), justify: "end" },
  id: "actions",
  cell: data => (
    <IconMenu
      icon={Icons.EllipsisVertical}
      menuDescription={t("description")}
      linkItems={[
        {
          description: t("row_actions.service_details"),
          href: agencyServicePath(data.agencyService.id),
        },
        {
          description: t("row_actions.create_service_referral"),
          href: newPreventionAgencyServiceReferralPath(),
        },
      ]}
      rightAligned
    />
  ),
};

const simpleCols = [
  ["start_date", true],
  ["end_date", true],
  ["billing_structure"],
  ["amount"],
].map(([name, sortBy]) => ({
  columnName: { name: t(name) },
  id: name,
  cell: data => data[_.camelCase(name)],
  sortBy: sortBy ? name : undefined,
}));

const columns = [
  ProviderNameColumn,
  ServiceNameColumn,
  ServiceRateNameColumn,
  ...simpleCols,
  ActionsColumn,
];
export default columns;
