import { useMutation, useQuery } from "@apollo/client";
import {
  Actions,
  InputDropdown,
  LoadingOverlay,
  SurfaceForm,
  Text,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";
import {
  previewFamilyFindingBulkOutreachCampaignPath,
  relationshipsDashboardAdminChildPath,
} from "routes";

import { translationWithRoot } from "@components/T";
import HtmlEditor from "@components/inputs/HtmlEditor";

import CreateBulkOutreachCampaign from "@graphql/mutations/CreateBulkOutreachCampaign.graphql";
import UpdateBulkOutreachCampaign from "@graphql/mutations/UpdateBulkOutreachCampaign.graphql";
import AgencyHumansByRelationshipIdsQuery from "@graphql/queries/AgencyHumansByRelationshipIds.graphql";
import BulkOutreachCampaign from "@graphql/queries/BulkOutreachCampaign.graphql";

import { getEncodedBase64SearchParams } from "@lib/base64SearchParams";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";

import {
  B64PARAMS,
  FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED,
  FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL,
} from "@root/constants";

import { filteredAddresses } from "./BulkOutreachCampaignSidebar";

const { t } = translationWithRoot(
  "family_finding.bulk_outreach_campaigns.draft"
);

/**
 * Component for writing or editing a bulk outreach campaign draft.
 *
 * Constitues the main body of the Bulk Outreach Campaign page when
 * not previewing the letters
 */
const BulkOutreachCampaignDraft = ({ id, childId, childAgencyHumanId }) => {
  const [createBulkOutreachCampaign, { loading: createLoading }] = useMutation(
    CreateBulkOutreachCampaign
  );
  const [updateBulkOutreachCampaign, { loading: updateLoading }] = useMutation(
    UpdateBulkOutreachCampaign
  );
  const relationshipIds = useBase64SearchParam("selectedIds") || [];
  const { data: recipients = { agencyHumansByRelationshipIds: [] } } = useQuery(
    AgencyHumansByRelationshipIdsQuery,
    {
      variables: {
        keystoneAgencyHumanId: childAgencyHumanId,
        relationshipIds: relationshipIds,
      },
    }
  );
  const { data: { bulkOutreachCampaign } = {}, loading: campaignLoading } =
    useQuery(BulkOutreachCampaign, {
      variables: { id },
      skip: !id,
    });
  const { formState, setFormAttribute } = useBintiForm(bulkOutreachCampaign);
  const routeParams = `${B64PARAMS}=${getEncodedBase64SearchParams()}`;
  const contactAgencyHumanIds = recipients.agencyHumansByRelationshipIds.reduce(
    (ids, { agencyHuman: { id: ahId, addresses, childId: ahChildId } }) => {
      if (ahChildId) return ids;
      const filteredAddrs = filteredAddresses(addresses);
      return filteredAddrs.length ? [...ids, ahId] : ids;
    },
    []
  );

  return (
    <Fragment>
      <Text textColor="emphasis-500" textStyle="emphasis-100">
        <If condition={!id}>{t("step_two")}</If>
        <If condition={id}>{t("edit")}</If>
      </Text>
      <LoadingOverlay active={campaignLoading}>
        <SurfaceForm
          title={t("relative_notification_letter")}
          actions={
            <Actions
              isSubmitting={createLoading || updateLoading}
              primaryDisabled={isEmpty(contactAgencyHumanIds)}
              primaryText={t("preview_letters")}
              primaryAction={async () => {
                let bulkOutreachCampaignId;
                if (id) {
                  ({
                    data: {
                      updateBulkOutreachCampaign: {
                        bulkOutreachCampaign: { id: bulkOutreachCampaignId },
                      },
                    },
                  } = await updateBulkOutreachCampaign({
                    variables: {
                      id,
                      contentTemplate: formState.contentTemplate,
                      familyFindingContactLogStatus:
                        formState.familyFindingContactLogStatus,
                    },
                  }));
                } else {
                  ({
                    data: {
                      createBulkOutreachCampaign: {
                        bulkOutreachCampaign: { id: bulkOutreachCampaignId },
                      },
                    },
                  } = await createBulkOutreachCampaign({
                    variables: {
                      childAgencyHumanIds: [childAgencyHumanId],
                      contactAgencyHumanIds,
                      contentTemplate: formState.contentTemplate,
                      familyFindingContactLogStatus:
                        formState.familyFindingContactLogStatus,
                    },
                  }));
                }
                window.location = `${previewFamilyFindingBulkOutreachCampaignPath(
                  { id: bulkOutreachCampaignId }
                )}?${routeParams}`;
              }}
              cancelHref={`${relationshipsDashboardAdminChildPath({
                id: childId,
              })}?${routeParams}`}
            />
          }
        >
          <InputDropdown
            required
            hideBlank
            label={t("contact_status")}
            onChange={setFormAttribute("familyFindingContactLogStatus")}
            value={formState.familyFindingContactLogStatus}
            values={[
              {
                label: I18n.t(
                  "activerecord.enums.family_finding_contact_log.status.successful"
                ),
                value: FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL,
              },
              {
                label: I18n.t(
                  "activerecord.enums.family_finding_contact_log.status.attempted"
                ),
                value: FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED,
              },
            ]}
          />
          <HtmlEditor
            id="bulk-outreach-campaign-content"
            label={t("content")}
            name={t("content")}
            value={formState.contentTemplate}
            onChange={setFormAttribute("contentTemplate")}
            fullWidth
            required
            printOnly
          />
        </SurfaceForm>
      </LoadingOverlay>
    </Fragment>
  );
};
BulkOutreachCampaignDraft.propTypes = {
  id: PropTypes.string,
  childId: PropTypes.number.isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
};

export default BulkOutreachCampaignDraft;
