import { translationWithRoot } from "@components/T";

import { formatAsLongDate } from "@lib/dates";

const { t } = translationWithRoot("common");

/**
 * Given a human's full name and date of birth, generates a label containing the
 * name and the date of birth in parentheses, if available.
 *
 * @param {string} fullName - The full name of the human.
 * @param {Date|string} [dateOfBirth] - The human's date of birth.
 * @param {Object} [options] - Options passed to `DateTime.fromISO`.
 * @returns {string} The human's full name, with date of birth appended in parentheses if availabe.
 */
const createNameAndDOBLabel = (fullName, dateOfBirth, options) =>
  dateOfBirth
    ? t("name_with_dob", {
        name: fullName,
        dob: formatAsLongDate(dateOfBirth, options),
      })
    : fullName;

export default createNameAndDOBLabel;
