import { useMutation } from "@apollo/client";
import {
  Flex,
  InputDate,
  InputCheckbox,
  Button,
  Modal,
  Text,
} from "@heart/components";
import I18n from "i18n-js";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState, Fragment } from "react";

import PersonCard from "@components/people/PersonCard";

import RemoveHumanPlacementProviderRole from "@graphql/mutations/RemoveHumanPlacementProviderRole.graphql";
import SendApplicationOtherAdultInviteEmail from "@graphql/mutations/SendApplicationOtherAdultInviteEmail.graphql";

import constructName from "../constructName";
import determineUserAgencyProfile from "../determineUserAgencyProfile";
import isPlaceholderEmail from "../isPlaceholderEmail";
import styles from "./OtherAdult.module.scss";
import {
  buildDeleteIconAction,
  buildEditIconAction,
  resendOtherAdultEmail,
} from "./otherAdultUtils";
import { getPlacementProviderChangeRefetchQueries } from "./placementProviderChangeUtils";
import removeOtherAdult from "./removeOtherAdult";

const OtherAdult = ({
  // Model data
  agencyId,
  adult,
  applicationId,
  placementProviderId,
  // Component configuration
  readOnly,
  canRemoveReadOnlyAdult,
  showDateOfBirth = false,
  showSubrole,
  agencyWorkerView = true,
  // React state
  setEditingForAdult,
  messageDisplay,
}) => {
  const [resending, setResending] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [removingNotPresentAdult, setRemovingNotPresentAdult] = useState(false);
  const [leftAtDate, setLeftAtDate] = useState(
    agencyWorkerView ? DateTime.local().toFormat("yyyy-MM-dd") : null
  ); // Only agency workers need to set this date
  const [isErroneousOtherAdult, setErroneousOtherAdult] = useState(false);

  const [removeHumanPlacementProviderRole] = useMutation(
    RemoveHumanPlacementProviderRole,
    {
      refetchQueries: getPlacementProviderChangeRefetchQueries({
        applicationId,
        placementProviderId,
      }),
    }
  );
  const [sendOtherAdultInviteEmail] = useMutation(
    SendApplicationOtherAdultInviteEmail
  );

  const userAgencyProfile = determineUserAgencyProfile({
    agencyId,
    person: adult,
  });

  const resendEmail = () => {
    if (!resending) {
      resendOtherAdultEmail({
        sendOtherAdultInviteEmail,
        setResending,
        userAgencyProfile,
        displayNotice: messageDisplay.displayNotice,
      });
    }
  };

  const removeAdult = ({
    setRemovingAdult,
    isErroneous,
    adultHasLeft = false,
  }) => {
    removeOtherAdult({
      messageDisplay: messageDisplay,
      applicationId,
      placementProviderId,
      userAgencyProfileId: userAgencyProfile.id,
      removeHumanPlacementProviderRole,
      leftAt: leftAtDate,
      isErroneous,
      setRemoving: setRemovingAdult,
      adultHasLeft,
      adultId: adult.id,
    });
  };

  const iconActions = readOnly
    ? []
    : [
        buildDeleteIconAction({ adult, removing, setRemoving }),
        buildEditIconAction({ adult, setEditingForAdult }),
      ];
  if (readOnly && canRemoveReadOnlyAdult && agencyWorkerView) {
    iconActions.push(
      buildDeleteIconAction({
        adult,
        removing: removingNotPresentAdult,
        setRemoving: setRemovingNotPresentAdult,
      })
    );
  }

  const phoneNumbers = adult.phoneNumbers || [];
  // The ||'s here help make sure that there aren't errors when the phone numbers
  // field is an empty array.
  const primaryNumber = (phoneNumbers.find(({ primary }) => primary) || {})
    .phoneNumber;

  const { email, dateOfBirth, hardBounce } = userAgencyProfile;

  let genericMessage;

  if (hardBounce) {
    genericMessage = I18n.t(
      "javascript.components.people.other_adults_form.other_adult.hard_bounce",
      { email, name: constructName(adult) }
    );
  }

  return (
    <Fragment>
      <PersonCard
        iconActions={iconActions}
        person={{
          ...adult,
          phoneNumber: primaryNumber,
          email: email,
          dateOfBirth: dateOfBirth,
        }}
        actions={
          <If
            condition={
              !isPlaceholderEmail({
                email: userAgencyProfile.email,
              })
            }
          >
            <span className={styles.actionsSection}>
              <Button variant="secondary" onClick={resendEmail}>
                {I18n.t(
                  `admin.applications.application.${
                    resending ? "sending_reminder_email" : "send_reminder_email"
                  }`
                )}
              </Button>
            </span>
          </If>
        }
        readOnly={readOnly}
        showDateOfBirth={showDateOfBirth}
        showSubrole={showSubrole}
        genericMessage={genericMessage}
      />
      {/* This modal is when we are removing an adult that is NOT read only (is editable) */}
      <Modal
        hidden={!removing}
        title={I18n.t(
          "views.applications.delete_application_adult.delete_confirmation"
        )}
        onCancel={() => setRemoving(false)}
        onSubmit={() =>
          removeAdult({
            setRemovingAdult: setRemoving,
            isErroneous: isErroneousOtherAdult,
          })
        }
        displayLargeTitle
      >
        <Flex column gap="300">
          <Text>
            {I18n.t(
              "views.applications.delete_application_adult.delete_warning"
            )}
          </Text>
          <If condition={agencyWorkerView}>
            <div className="contains-inputs">
              <InputDate
                label={I18n.t(
                  "views.applications.application_other_adult.left_at_date"
                )}
                name="adult[left_at]"
                id="left-at-picker"
                value={leftAtDate}
                minDate={adult.joinedAt}
                onChange={setLeftAtDate}
                required
              />
              <InputCheckbox
                value={isErroneousOtherAdult}
                onChange={() => setErroneousOtherAdult(!isErroneousOtherAdult)}
                label={I18n.t(
                  "views.applications.application_other_adult.created_in_error"
                )}
              />
            </div>
          </If>
        </Flex>
      </Modal>
      {/* This modal is when we are removing an adult that IS "read only" (cannot be edited) */}
      <Modal
        hidden={!removingNotPresentAdult}
        title={I18n.t(
          "views.applications.delete_application_adult.delete_confirmation"
        )}
        onCancel={() => setRemovingNotPresentAdult(false)}
        onSubmit={() =>
          removeAdult({
            setRemovingAdult: setRemovingNotPresentAdult,
            isErroneous: true,
            adultHasLeft: true,
          })
        }
        displayLargeTitle
      >
        <Text>
          {I18n.t("views.applications.application_other_adult.really_delete")}
        </Text>
      </Modal>
    </Fragment>
  );
};

OtherAdult.propTypes = {
  agencyId: PropTypes.number.isRequired,
  adult: PropTypes.shape({
    id: PropTypes.string,
    userId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    subrole: PropTypes.string,
    joinedAt: PropTypes.string,
    leftAt: PropTypes.string,
    masqueradeLink: PropTypes.string,
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        phoneNumber: PropTypes.string,
        primary: PropTypes.bool,
      })
    ),
    userAgencyProfiles: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        dateOfBirth: PropTypes.string,
        hardBounce: PropTypes.bool,
      })
    ),
  }),
  applicationId: PropTypes.number.isRequired,
  placementProviderId: PropTypes.number.isRequired,
  setEditingForAdult: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  agencyWorkerView: PropTypes.bool,
  /* Boolean flag as to whether to we want to show/capture date of birth,
   this varies depending on context of who is viewing the component.
   Defaults to true (see above)   */
  showDateOfBirth: PropTypes.bool,
  showSubrole: PropTypes.bool,
  messageDisplay: PropTypes.object.isRequired,
  canRemoveReadOnlyAdult: PropTypes.bool,
};

export default OtherAdult;
