import { useQuery } from "@apollo/client";
import { Layout, LoadingOverlay } from "@heart/components";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import SurfaceBase from "@heart/components/surface/SurfaceBase";
import { useState } from "react";

import AgencyServiceReferralsForm from "@components/prevention/agency_service_referrals/form/AgencyServiceReferralsForm";

import AgencyAutocompleteQuery from "@graphql/queries/AgencyAutocomplete.graphql";
import CurrentUser from "@graphql/queries/CurrentUser.graphql";

const AgencyServiceReferralsFormPage = () => {
  const [referringAgencyId, setReferringAgencyId] = useState();

  const { data, loadingCurrentUser } = useQuery(CurrentUser);
  if (data?.user?.agencyId) {
    setReferringAgencyId(data.user.agencyId);
  }

  const content = (
    <LoadingOverlay active={loadingCurrentUser}>
      <If condition={data?.user?.bintiAdmin}>
        <SurfaceBase title={"Select Referring Agency (Binti Admin Only)"}>
          <InputAutocompleteGraphQL
            query={AgencyAutocompleteQuery}
            isClearable
            valuesFromResponse={resp =>
              resp.agencyMatches.map(agency => ({
                label: agency.name,
                value: agency.id,
              }))
            }
            onChange={agency => setReferringAgencyId(agency?.value)}
          />
        </SurfaceBase>
      </If>
      <If condition={Boolean(referringAgencyId)}>
        <AgencyServiceReferralsForm referringAgencyId={referringAgencyId} />
      </If>
    </LoadingOverlay>
  );

  return <Layout pageTitle={"Create New Referral"} main={{ content }} />;
};

export default AgencyServiceReferralsFormPage;
