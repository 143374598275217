import { GraphQLDataTable } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { profileAdminChildPath } from "routes";

import { translationWithRoot } from "@components/T";

import AgencyServiceReferrals from "@graphql/queries/prevention/AgencyServiceReferrals.graphql";

const { t } = translationWithRoot(
  "prevention.agency_service_referrals.dashboard"
);

const DashboardTable = ({ status, currentUserAgencyId }) => {
  const columns = [
    {
      id: "children",
      columnName: { name: t("columns.children") },
      cell: data => {
        const humans = data.receivingHumans.map(human => (
          <Fragment key={human.childId}>
            <a href={profileAdminChildPath(human.childId)}>{human.fullName}</a>
            <br />
          </Fragment>
        ));

        return <Fragment>{humans}</Fragment>;
      },
    },
    {
      id: "start_date",
      columnName: { name: t("columns.service_start_date") },
      cell: data => new Date(data.startDate).toLocaleDateString(),
    },
    {
      id: "end_date",
      columnName: { name: t("columns.service_end_date") },
      cell: data => new Date(data.endDate).toLocaleDateString(),
    },
    {
      id: "service_name",
      columnName: { name: t("columns.service_name") },
      cell: data => data.agencyService.name,
    },
    {
      id: "agency",
      columnName: { name: t("columns.agency") },
      cell: data => {
        const { referringAgency, providerAgency } = data.agencyService;

        // The default is to explicitly state the referring and provider agencies
        // At the moment, this can only happen for Binti Admins.
        let sentence = [
          referringAgency.name,
          t("columns.agency_sent_to").toLowerCase(),
          providerAgency.name,
        ];

        if (referringAgency.id === providerAgency.id) {
          sentence = [t("columns.agency_self_assigned")];
        } else if (referringAgency.id === currentUserAgencyId) {
          sentence = [t("columns.agency_sent_to"), providerAgency.name];
        } else if (providerAgency.id === currentUserAgencyId) {
          sentence = [t("columns.agency_received_from"), referringAgency.name];
        }

        return sentence.join(" ");
      },
    },
    {
      id: "actions",
      columnName: { name: t("columns.actions"), justify: "end" },
      cell: () => <a href={"#view_service"}>View Service</a>,
    },
  ];

  const filters = [
    {
      label: t("filters.children_associated"),
      type: "search",
      field: "receivingHumanNameContains",
    },
    {
      label: t("filters.service_name"),
      type: "search",
      field: "serviceNameContains",
    },
    {
      // TODO(ENG-19704) - Implement on backend
      label: "",
      type: "custom_dates",
      field: "startDate",
      customDatesLabels: {
        start: t("filters.service_start_date_after"),
        end: t("filters.service_start_date_before"),
      },
    },
    {
      // TODO(ENG-19704) - Implement on backend
      label: "",
      type: "custom_dates",
      field: "endDate",
      customDatesLabels: {
        start: t("filters.service_end_date_after"),
        end: t("filters.service_end_date_before"),
      },
    },
  ];

  return (
    <GraphQLDataTable
      columns={columns}
      query={AgencyServiceReferrals}
      queryTitle={"preventionAgencyServiceReferral"}
      defaultFilters={{ status }}
      filtersToPreserveOnClear={["status"]}
      filters={filters}
    />
  );
};

DashboardTable.propTypes = {
  status: PropTypes.string.isRequired,
  currentUserAgencyId: PropTypes.string,
};

export default DashboardTable;
