import { InputText } from "@heart/components";
import { snakeCase } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import getErrorsForField from "./getErrorsForField";

const { t } = translationWithRoot("admin.integrations", {
  escapeJavascriptRoot: true,
});

// This form is based on lib/json_schemas/integration_configurations/unity.json
// and in the future, we could likely generate these forms from other JSON schema files
const UnityConfigurationForm = ({
  formState,
  setFormAttribute,
  validationErrors,
}) => {
  const errorsForField = getErrorsForField(validationErrors);

  const fieldsRequired = {
    username: true,
    password: true,
    clientId: true,
    clientSecret: true,
    peopleEndpoint: true,
    loginEndpoint: true,
    placementsEndpoint: false,
    facilitiesEndpoint: false,
  };

  return (
    <Fragment>
      {Object.keys(fieldsRequired).map(key => (
        <InputText
          name={key}
          key={key}
          label={t(`labels.${snakeCase(key)}`)}
          value={formState[key]}
          onChange={setFormAttribute(key)}
          error={errorsForField(key)}
          required={fieldsRequired[key]}
        />
      ))}
    </Fragment>
  );
};

UnityConfigurationForm.propTypes = {
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
  validationErrors: PropTypes.array,
};
export default UnityConfigurationForm;
