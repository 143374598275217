import classNames from "classnames";
import { useEffect, useState } from "react";

import T from "@components/T";
import AfcarsRequired from "@components/ccwis_demo/AfcarsRequired";
import Label from "@components/reusable_ui/forms/Label";

import { useGeneratedId } from "@lib/generateId";

import inputStyles from "../Input.module.scss";

/**
 * React hooks for extracting common input functionality for all inputs that share
 * the same set of core `props` (described in `inputCommonPropTypes.js`).
 *
 * Call these hooks with all of the `props` that the input was given, and they will
 * return a bunch of helpful items for reducing boilerplate in your input.
 */

/*
 * Return a fully-rendered label for the input.  Usually you'll just pass
 * a string, but you can also pass a function (render props pattern [1]) that
 * returns a Label component and the basic props to pass Label and you can
 * customize the label in specific instances.
 * [1]: https://reactjs.org/docs/render-props.html
 */
export const useInputLabel = ({
  id,
  label,
  hideLabel,
  labeledExternally,
  required,
  afcarsRequired = false,
}) => {
  if (labeledExternally) return null;

  // label as a function is not part of the API - it is only used as an
  // implementation detail for internal heart usage. If you're in here looking
  // for how to use label outside of heart, please don't rely on it accepting a
  // function.
  if (typeof label === "function") {
    return label({
      Label,
      htmlFor: id,
      hide: hideLabel,
      required,
      afcarsRequired,
    });
  }

  return (
    <Label htmlFor={id} hide={hideLabel} required={required}>
      {label}
      <If condition={afcarsRequired}>
        {" "}
        <AfcarsRequired />
      </If>
    </Label>
  );
};

/** Return an ID, generating one if necessary */
export const useInputId = ({ id }) => {
  const autoId = useGeneratedId();
  return id || autoId;
};

/** If a description is present, return its JSX */
export const useInputDescription = ({ description }) => {
  if (!description) return null;
  if (typeof description === "string" || description.type === T)
    return <p className={inputStyles.description}>{description}</p>;
  return description;
};

/** If an error is present, return its JSX */
export const useInputError = ({ error }) => (
  <If condition={error}>
    <p data-testid="inline-errors" className="inline-errors">
      {error}
    </p>
  </If>
);

/** Based on this component's props, returns a className for the
 * input container element.
 */
export const useInputContainerClassName = ({ hidden, fullWidth }) =>
  classNames({
    [inputStyles.hide]: hidden,
    [inputStyles.fullWidth]: fullWidth,
  });

/**
 * These are the standard props that should be passed-on to
 * the underlying `<input>` element, extracted for your convenience!
 */
export const useInputProps = ({
  id,
  name,
  disabled,
  required,
  "data-testid": testId,
}) => ({
  id,
  name,
  disabled,
  required,
  "data-testid": testId,
});

export const useSimpleControlledInputProps = ({ onChange, onBlur, value }) => {
  const [textValue, setTextValue] = useState(value || "");
  useEffect(() => setTextValue(value || ""), [value]);
  const handleChange = e => {
    setTextValue(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  return {
    value: textValue,
    onChange: handleChange,
    onBlur,
  };
};
