import { Flex, Text } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import { getSearchParams } from "@lib/searchParams";

import styles from "../Login.module.scss";
import OtpSend from "./OtpSend";
import OtpVerify from "./OtpVerify";

const { T } = translationWithRoot("permissions.otp_login");

const PageWrapper = ({ children }) => (
  <Flex column align="center">
    <div className={styles.background}></div>
    <div className={styles.circles}></div>
    <Flex column align="center" className={styles.container} gap="300">
      <Text textStyle="emphasis-300">
        <T t="log_in_to_binti" />
      </Text>
      {children}
    </Flex>
  </Flex>
);

PageWrapper.propTypes = {
  children: PropTypes.node,
};

const OtpLogin = ({ agencyAdminAssignmentId, phoneNumbers, email }) => {
  const showVerificationPage = getSearchParams().get("verify") === "true";

  if (showVerificationPage) {
    return (
      <PageWrapper>
        <OtpVerify
          selectedPhoneNumberId={localStorage.getItem("otpPhoneNumberId")}
          verificationMethod={localStorage.getItem("preferredMethod")}
          agencyAdminAssignmentId={localStorage.getItem(
            "agencyAdminAssignmentId"
          )}
        />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <OtpSend
        agencyAdminAssignmentId={agencyAdminAssignmentId}
        phoneNumbers={phoneNumbers}
        email={email}
      />
    </PageWrapper>
  );
};

OtpLogin.propTypes = {
  agencyAdminAssignmentId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumbers: PropTypes.arrayOf(PropTypes.object),
};
export default OtpLogin;
