import { Upload, Surface, PageContainer } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";

import { ajaxDefaults } from "../../clients/common";
import Photo from "./Photo";

const { t } = translationWithRoot("photos");

const PhotoUploader = ({
  photos: ps,
  canUploadChildPhoto,
  canDeleteChildPhoto,
  uploadPath,
  deletePath,
}) => {
  const [photos, setPhotos] = useState(ps);

  if (!canUploadChildPhoto) return null;

  const onDrop = ([photoFile]) => {
    const data = new FormData();
    data.append("photo", photoFile);

    // TODO [ENG-4047] port this to GraphQL
    return $.ajax({
      ...ajaxDefaults(),
      url: uploadPath,
      cache: false,
      contentType: false,
      processData: false,
      data,
      method: "post",
      dataType: "json",
      success: setPhotos,
    });
  };

  const onDelete = photoId => {
    setPhotos(photos.filter(photo => photo.id !== photoId));

    const data = new FormData();
    data.append("photo_id", photoId);

    // TODO [ENG-4047] port this to GraphQL
    $.ajax({
      ...ajaxDefaults(),
      url: deletePath,
      cache: false,
      contentType: false,
      processData: false,
      data,
      method: "delete",
      dataType: "json",
    });
  };

  return (
    <PageContainer>
      <Surface title={t("upload_photos_section")} data-testid="photo-uploader">
        <Upload photosOnly onUpload={onDrop} multiple={false} />
      </Surface>
      <Surface title={t("child_photos_section")}>
        {photos.map(photo => (
          <Photo
            photo={photo}
            key={photo.id}
            onDelete={onDelete}
            isDeleteDisabled={!canDeleteChildPhoto}
          />
        ))}
        <If condition={isEmpty(photos)}>{t("no_photos_to_display")}</If>
      </Surface>
    </PageContainer>
  );
};

PhotoUploader.propTypes = {
  photos: PropTypes.array.isRequired,
  uploadPath: PropTypes.string.isRequired,
  deletePath: PropTypes.string.isRequired,
  canUploadChildPhoto: PropTypes.bool.isRequired,
  canDeleteChildPhoto: PropTypes.bool.isRequired,
};

export default PhotoUploader;
