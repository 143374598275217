import { Link, HeartTable, Button } from "@heart/components";
import I18n from "i18n-js";
import { isNil } from "lodash";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import { formatAsLongDate } from "@lib/dates";

const { Table, Caption, Td, Th, Thead, Tr, Tbody } = HeartTable;

const { t } = translationWithRoot(
  "agency_human.form.personal_info.potential_matches"
);

const PotentialAgencyHumanMatches = ({ matches, setFormAttribute }) => (
  <Table>
    <Caption>{t("did_you_mean")}</Caption>
    <Thead>
      <Tr>
        <Th>{t("name")}</Th>
        <Th>{t("actions")}</Th>
      </Tr>
    </Thead>
    <Tbody>
      {matches.map(({ id, fullName, dateOfBirth, linkToView, ...rest }) => (
        <Tr key={id}>
          <Td>
            <Link target="_blank" href={linkToView}>
              {isNil(dateOfBirth)
                ? fullName
                : I18n.t(
                    "javascript.components.agency_human.form.search_result_with_dob",
                    { name: fullName, dob: formatAsLongDate(dateOfBirth) }
                  )}
            </Link>
          </Td>
          <Td>
            <Button
              variant="secondary"
              onClick={() =>
                setFormAttribute("agencyHuman")({
                  value: id,
                  label: fullName,
                  id,
                  fullName,
                  dateOfBirth,
                  ...rest,
                })
              }
            >
              {t("select_person")}
            </Button>
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

PotentialAgencyHumanMatches.propTypes = {
  /** A list of agency humans found using fuzzy find */
  matches: PropTypes.arrayOf(PropTypes.shape({})),
  setFormAttribute: PropTypes.func.isRequired,
};

export default PotentialAgencyHumanMatches;
