import classNames from "classnames";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import { translationWithRoot } from "@components/T";

import {
  inputCommonPropTypes,
  textualInputPropTypes,
  useSimpleControlledInputProps,
  BasicInputLayout,
} from "./common";

const { t } = translationWithRoot("heart.components.inputs.input_text");

// See https://regex101.com/r/2E4cKl/1 for examples of what this pattern asserts
const EMAIL_REGEX = /^['\w+\-.]+@[A-Za-z\d\-]+(\.[A-Za-z\d\-]+)*\.[A-Za-z]{2,}$/ // eslint-disable-line
  .source;

/**
 * This `InputText` component covers all the "textual" inputs
 * you'll need.  By default it renders a `text` input - choose
 * `type="text|email|search|password|number|tel"` for
 * other kinds of text input.
 */
const InputText = forwardRef((props, ref) => {
  const {
    pattern,
    placeholder,
    type = "text",
    maxCharacters,
    onBlur = () => {},
    className,
  } = props;
  const controlledInputProps = useSimpleControlledInputProps(props);
  return (
    <BasicInputLayout
      {...props}
      inputComponent={commonInputProps => {
        const inputProps = {
          ...commonInputProps,
          ...controlledInputProps,
          pattern: type === "email" ? pattern || EMAIL_REGEX : pattern,
          title: type === "email" ? t("email_title") : null,
          placeholder,
          className: classNames("form-control", className),
          maxLength: maxCharacters,
          onBlur,
        };
        return (
          <Choose>
            <When condition={type === "number"}>
              <input
                type="number"
                onWheel={e => {
                  // By default, when you scroll on a number input, the value increments/decrements.
                  // Users end up submitting inaccurate data because they tried to scroll the page.
                  // Fixing this is challenging and kind of hacky.  Here's the solution from
                  // https://medium.com/modernnerd-code/how-to-disabled-scrolling-on-html-number-input-in-react-6548841166fb

                  e.target.blur();
                  e.stopPropagation();
                  setTimeout(() => e.target.focus(), 0);
                }}
                {...inputProps}
                step={props.step}
                ref={ref}
              />
            </When>
            <Otherwise>
              <input type={type} {...inputProps} ref={ref} />
            </Otherwise>
          </Choose>
        );
      }}
    />
  );
});

InputText.displayName = "InputText";
InputText.propTypes = {
  ...inputCommonPropTypes,
  ...textualInputPropTypes,
  /** Regex for HTML validation of this field.  Not applicable for `type="number"`. */
  pattern: PropTypes.string,
  /**
   * Which `<input type="" />` to use.  See [this table](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types)
   * for more information about this field.
   *
   * Defaults to `text`.
   * * Use `email` for email inputs - it comes with built-in HTML validation!
   * * Use `number` for numbers that can be incremented/decremented, not for things like ZIP codes.
   * * Use `search` for search fields - it comes with its own "clear" button
   * * `tel` is for telephone numbers, but `<InputPhone />` does a much better job at it.
   */
  type: PropTypes.oneOf([
    "text",
    "email",
    "number",
    "password",
    "search",
    "tel",
  ]),
  /** The initial (or current) field value as a string */
  value: PropTypes.string,
  /** Invoked with the current field value as an argument */
  onChange: PropTypes.func,
  /** TODO How much to increase number inputs by */
  step: PropTypes.string,
};

export default InputText;
