import { indexOf, isNil, sortBy, upperCase } from "lodash";
import { DateTime } from "luxon";

import { translationWithRoot } from "@components/T";

import {
  RELATIVE,
  FICTIVE_KIN,
  NO_RELATIONSHIP,
  UNKNOWN,
  OTHER_PARTNER_STATUS,
  OTHER,
  PARTNER_STATUSES,
  PLACEMENT,
  NON_PLACEMENT,
  FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED,
  FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL,
} from "@root/constants";

const { t } = translationWithRoot("activerecord.enums.relationships", {
  escapeJavascriptRoot: true,
});

export const sortRelationships = {
  highestRankingOptionFromArray: ({ list, values }) =>
    /** We're inverting the index here so that we can keep the ordering
     * consistent between highestRankingOptionFromArray and sortByList,
     * where the most desireable option comes last
     */
    sortBy(values, option => -indexOf(list, option))[0],
  sortByList:
    ({ key, list }) =>
    ({ [key]: keyA }, { [key]: keyB }) => {
      const indexA = list.indexOf(keyA);
      const indexB = list.indexOf(keyB);
      return indexA >= indexB ? 1 : -1;
    },
};

export const determineName = ({
  keystoneAgencyHumanId,
  destinationAgencyHuman: {
    id: destinationAgencyHumanId,
    fullName: destinationAgencyHumanFullName,
    linkToView: destinationAgencyHumanLinkToView,
  },
  sourceAgencyHuman: {
    fullName: sourceAgencyHumanFullName,
    linkToView: sourceAgencyHumanLinkToView,
  },
}) =>
  destinationAgencyHumanId === keystoneAgencyHumanId
    ? { href: sourceAgencyHumanLinkToView, name: sourceAgencyHumanFullName }
    : {
        href: destinationAgencyHumanLinkToView,
        name: destinationAgencyHumanFullName,
      };

export const sortByName =
  ({ keystoneAgencyHumanId }) =>
  (
    {
      destinationAgencyHuman: {
        id: destinationAId,
        fullName: destinationAFullName,
      },
      sourceAgencyHuman: { fullName: sourceAFullName },
    },
    {
      destinationAgencyHuman: {
        id: destinationBId,
        fullName: destinationBFullName,
      },
      sourceAgencyHuman: { fullName: sourceBFullName },
    }
  ) => {
    const { name: nameA } = determineName({
      keystoneAgencyHumanId,
      destinationAgencyHuman: {
        id: destinationAId,
        fullName: destinationAFullName,
      },
      sourceAgencyHuman: {
        fullName: sourceAFullName,
      },
    });

    const { name: nameB } = determineName({
      keystoneAgencyHumanId,
      destinationAgencyHuman: {
        id: destinationBId,
        fullName: destinationBFullName,
      },
      sourceAgencyHuman: {
        fullName: sourceBFullName,
      },
    });

    return upperCase(nameA) < upperCase(nameB) ? 1 : -1;
  };

export const determineRelationshipTypeForTable = ({
  lineageType,
  kinshipRelationship,
  kinshipRelationshipOtherDescription,
  fictiveKinDescription,
}) => {
  if ([RELATIVE, NO_RELATIONSHIP, UNKNOWN].includes(kinshipRelationship)) {
    return t(`category.${kinshipRelationship}`);
  }
  if (kinshipRelationship === FICTIVE_KIN) {
    return fictiveKinDescription;
  }
  if ([OTHER, OTHER_PARTNER_STATUS].includes(kinshipRelationship)) {
    return kinshipRelationshipOtherDescription;
  }
  if (PARTNER_STATUSES.includes(kinshipRelationship)) {
    return t(`partner_status.${kinshipRelationship}`);
  }
  return `${t(`kinship_relationships.${kinshipRelationship}`)}${
    isNil(lineageType) ? "" : ` (${t(`lineage_type.${lineageType}`)})`
  }`;
};

export const sortByRelationshipType = (relationshipA, relationshipB) => {
  const relationshipTypeA = determineRelationshipTypeForTable(relationshipA);
  const relationshipTypeB = determineRelationshipTypeForTable(relationshipB);

  return relationshipTypeA < relationshipTypeB ? 1 : -1;
};

export const sortByContactStatus = (
  { latestContactLog: latestContactLogA },
  { latestContactLog: latestContactLogB }
) => {
  const { status: statusA, contactedOn: contactedOnA } =
    latestContactLogA || {};
  const { status: statusB, contactedOn: contactedOnB } =
    latestContactLogB || {};

  /** if statuses are the same, sort by date descending */
  const statusesAreEqual = statusA === statusB;
  if (statusesAreEqual)
    return DateTime.fromISO(contactedOnA) >= DateTime.fromISO(contactedOnB)
      ? 1
      : -1;

  /** otherwise sort by contact status */
  const statusOrder = [
    FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED,
    FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL,
  ];
  return sortRelationships.sortByList({
    key: "contactStatus",
    list: statusOrder,
  })({ contactStatus: statusA }, { contactStatus: statusB });
};

export const filterLevelsOfSupport = levelsOfSupport =>
  Object.keys(levelsOfSupport || {}).filter(
    los => ![PLACEMENT, NON_PLACEMENT].includes(los)
  );

export const sortByLevelsOfSupport = (
  { levelsOfSupport: levelsOfSupportA },
  { levelsOfSupport: levelsOfSupportB }
) => {
  const losA = filterLevelsOfSupport(levelsOfSupportA);
  const losB = filterLevelsOfSupport(levelsOfSupportB);

  if (losA.length !== losB.length) {
    return losA.length >= losB.length ? 1 : -1;
  }
  return losA[0] < losB[0] ? 1 : -1;
};

export const sortByPlacementProviderEligibility = (
  { placementProviderEligibilities: placementProviderEligibilitiesA },
  { placementProviderEligibilities: placementProviderEligibilitiesB }
) => {
  const placementProviderEligibilityOrder = [
    "prohibited",
    "not_recommended",
    "not_applicable",
    "unable",
    "unwilling",
    "willing",
    "recommended",
    "current_placement",
  ];
  const placementProviderEligibilityA =
    sortRelationships.highestRankingOptionFromArray({
      list: placementProviderEligibilityOrder,
      values: placementProviderEligibilitiesA,
    });
  const placementProviderEligibilityB =
    sortRelationships.highestRankingOptionFromArray({
      list: placementProviderEligibilityOrder,
      values: placementProviderEligibilitiesB,
    });

  return sortRelationships.sortByList({
    key: "placementProviderEligibility",
    list: placementProviderEligibilityOrder,
  })(
    { placementProviderEligibility: placementProviderEligibilityA },
    { placementProviderEligibility: placementProviderEligibilityB }
  );
};

export const sortByEmotionalRelationship = (
  { emotionalRelationshipStatuses: emotionalRelationshipStatusesA },
  { emotionalRelationshipStatuses: emotionalRelationshipStatusesB }
) => {
  const emotionalRelationshipOrder = [
    "no_relationship",
    "unknown",
    "contentious",
    "distant",
    "cordial",
    "close",
  ];
  const emotionalRelationshipsA =
    sortRelationships.highestRankingOptionFromArray({
      list: emotionalRelationshipOrder,
      values: emotionalRelationshipStatusesA,
    });
  const emotionalRelationshipsB =
    sortRelationships.highestRankingOptionFromArray({
      list: emotionalRelationshipOrder,
      values: emotionalRelationshipStatusesB,
    });

  return sortRelationships.sortByList({
    key: "emotionalRelationshipStatuses",
    list: emotionalRelationshipOrder,
  })(
    { emotionalRelationshipStatuses: emotionalRelationshipsA },
    { emotionalRelationshipStatuses: emotionalRelationshipsB }
  );
};
