$(() => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
  function configureDropdown(_index, obj) {
    $(obj)
      .find("> .js-dropdown-trigger")
      .on("click touchend", function (e) {
        const trigger = $(obj).find(".js-dropdown-trigger");
        const clickedOpen = trigger.hasClass("js-clicked-open");
        if (!clickedOpen) {
          $(".js-clicked-open").trigger("click"); // close all other open dropdowns
          trigger.addClass("js-clicked-open");
          openDropdown(e);
        } else {
          trigger.removeClass("js-clicked-open");
          closeDropdown(e);
        }
      });

    if ($(obj).find(".js-no-hover").length === 0) {
      $(obj)
        .find("> .js-dropdown-trigger")
        .on("focusin mouseenter", function (e) {
          if ($(e.target).data("js-clicked-open")) {
            return;
          }

          if ($(obj).find(".js-dropdown-menu").first().hasClass("is-hidden")) {
            openDropdown(e);
            $(obj).one("mouseleave", closeDropdown);
          }
        });
    }

    function closeDropdown(e) {
      e.preventDefault();
      $(obj).find(".js-dropdown-menu").first().addClass("is-hidden");
      $(obj)
        .find(".js-caret")
        .addClass("fa-caret-down")
        .removeClass("fa-caret-up");
    }

    function openDropdown(e) {
      const scrollingContainer = $(obj).parents(".responsive-table-wrapper");
      let moveLeft = 0;
      let offsetRight = 0;
      e.preventDefault();

      if ($(obj).find(".js-dropdown-trigger").hasClass("disabled")) {
        return;
      }

      $(obj).find(".js-dropdown-menu").first().removeClass("is-hidden");

      // where we call offset() in this function, we used to call position()
      // the behavior of position() changed between jQuery v3.2.1 and v.3.3.0
      // cf. https://github.com/jquery/api.jquery.com/issues/1081
      // NOTE there may be other bugs associated with the jQuery change
      offsetRight =
        $("body").width() -
        $(obj).find(".js-dropdown-trigger").offset().left -
        15;

      /* Make the menu go to the left of the trigger on small screens */
      if (offsetRight < $(obj).find(".js-dropdown-menu").width()) {
        moveLeft += $(obj).find(".js-dropdown-menu").width() - offsetRight;
      }
      if (scrollingContainer.length > 0) {
        /* This is only necessary for Chrome, Firefox handles the CSS differently */
        if (
          $(obj).find(".js-dropdown-trigger").offset().left <
          $(obj).find(".js-dropdown-menu").offset().left
        ) {
          moveLeft += scrollingContainer.scrollLeft();
        }
      }
      if (!$(obj).hasClass("batch_actions_selector")) {
        $(obj)
          .find(".js-dropdown-menu")
          .css("margin-left", 0 - moveLeft);
      }

      $(obj)
        .find(".js-caret")
        .addClass("fa-caret-up")
        .removeClass("fa-caret-down");

      // sometimes opening a dropdown will add a scrollbar to the page, which changes the page size
      resizeTopScrollbar();

      if ($(obj).find(".segmented-control").length === 0) {
        addToggleCasesButtons();
      }

      // Add extra submit and clear buttons to the top of the filters menu
      // exactly once, where we assume each set is inside a .buttons div
      if ($(obj).find(".buttons").length === 1) {
        addExtraSubmitAndClearButtonsAtTopOfFilters();
      }
    }

    function addToggleCasesButtons() {
      const $dropdownTrigger = $(obj).find(".js-dropdown-trigger");
      const allApplicationsPath = $dropdownTrigger.data("allApplicationsPath");
      const superviseesApplicationsPath = $dropdownTrigger.data(
        "superviseesApplicationsPath"
      );
      const userApplicationsPath = $dropdownTrigger.data(
        "userApplicationsPath"
      );
      const $buttons = $("<div class = 'segmented-control'></div>");
      const currentPath = window.location.pathname + window.location.search;
      $buttons.insertBefore($(obj).find(".filter_form"));

      if (superviseesApplicationsPath || userApplicationsPath) {
        $buttons.append(
          createToggleCaseButtonHtml(
            "ALL CASES",
            allApplicationsPath,
            allApplicationsPath === currentPath
          )
        );
      }

      if (superviseesApplicationsPath) {
        $buttons.append(
          createToggleCaseButtonHtml(
            "MY TEAM'S CASES",
            superviseesApplicationsPath,
            superviseesApplicationsPath === currentPath
          )
        );
      }

      if (userApplicationsPath) {
        $buttons.append(
          createToggleCaseButtonHtml(
            "MY CASES",
            userApplicationsPath,
            userApplicationsPath === currentPath
          )
        );
      }
    }

    function createToggleCaseButtonHtml(buttonText, link, disabled) {
      let classes = "button-link segment";
      classes = disabled ? classes + " disabled" : classes;
      return (
        "<a class='" + classes + "' href='" + link + "'>" + buttonText + "</a>"
      );
    }

    function addExtraSubmitAndClearButtonsAtTopOfFilters() {
      const $extraSubmitAndClearButtons = $(
        "<div class='buttons top-buttons'></div>"
      );
      $extraSubmitAndClearButtons.append(
        "<input type='submit' name='commit' value='" +
          I18n.t("active_admin.filters.buttons.filter") +
          "' data-disable-with='Filter'>"
      );
      $extraSubmitAndClearButtons.append(
        "<a class='clear_filters_btn' href='#'>" +
          I18n.t("active_admin.filters.buttons.clear") +
          "</a>"
      );
      $(obj).find(".filter_form").prepend($extraSubmitAndClearButtons);
    }
  }

  function configureResponsiveTable() {
    const hideColumnStorageKey = window.location.pathname + "/hide_columns";
    const $primaryTable = $("#main_content_wrapper .js-responsive-table table");
    let $hideColumnsList;
    $(".js-responsive-table").each(wrapResponsiveTable);

    if ($(".js-responsive-table-column-select").length > 0) {
      // :not(.hide-title) excludes empty columns from the hide columns checklist
      // such as Actions and bulk selections
      $primaryTable.find("th:not(.hide-title)").each(addColumnSelect);
      $hideColumnsList = $(
        ".js-responsive-table-column-select input[type='checkbox']"
      );
      if ($hideColumnsList.length > 0) {
        updateTableColumns();
        $hideColumnsList.on("change", toggleColumns);
      } else {
        $(".js-responsive-table-column-select").hide();
      }
    }

    function wrapResponsiveTable(_idx, obj) {
      $(obj)
        .find("table.index")
        .wrap(
          "<div class='responsive-table-wrapper js-responsive-table-wrapper'></div>"
        );
    }

    function addColumnSelect(columnIndex, column) {
      const hideColumns = JSON.parse(
        localStorage.getItem(hideColumnStorageKey)
      );
      const $checkbox = $(
        "<li><label><input type='checkbox' checked data-column='" +
          (columnIndex + 1) +
          "'>" +
          $(column).text() +
          "</label>"
      );
      if (hideColumns && hideColumns[columnIndex]) {
        $checkbox.find("input[type='checkbox']").prop("checked", false);
      }
      $(".js-responsive-table-column-select .js-dropdown-menu").append(
        $checkbox
      );
    }

    function storeColumns() {
      const hideColumns = $.map($hideColumnsList, function (checkbox) {
        return !checkbox.checked;
      });
      localStorage.setItem(hideColumnStorageKey, JSON.stringify(hideColumns));
    }

    function updateTableColumns() {
      const hideColumns = JSON.parse(
        localStorage.getItem(hideColumnStorageKey)
      );
      $.each(hideColumns, updateTableColumn);
      resizeTopScrollbar();
    }

    function toggleColumns() {
      storeColumns();
      updateTableColumns();
    }

    function updateTableColumn(idx, hideColumn) {
      const className = "collapse-column-" + (idx + 1);
      if (hideColumn) {
        $primaryTable.addClass(className);
      } else {
        $primaryTable.removeClass(className);
      }
    }
  }

  function resizeTopScrollbar() {
    // - 2 makes this a little more resistant to browser pixel differences
    $(".top-scrollbar").width(
      $(".js-responsive-table-wrapper table").width() - 2
    );
  }

  function configureTopScrollbar() {
    let lastTriggeredMain = Date.now();
    let lastTriggeredTop = Date.now();
    const $tableWrapper = $(
      "#main_content_wrapper .js-responsive-table-wrapper"
    );
    const $scrollbarWrapper = $(".js-top-scrollbar-wrapper");
    resizeTopScrollbar();

    function moveMainScrollArea() {
      if (Date.now() - lastTriggeredTop > 1000) {
        lastTriggeredMain = Date.now();
        $tableWrapper.scrollLeft($scrollbarWrapper.scrollLeft());
      }
    }
    function moveTopScrollArea() {
      if (Date.now() - lastTriggeredMain > 1000) {
        lastTriggeredTop = Date.now();
        $scrollbarWrapper.scrollLeft($tableWrapper.scrollLeft());
      }
    }

    $scrollbarWrapper.on("scroll", moveMainScrollArea);
    $tableWrapper.on("scroll", moveTopScrollArea);
  }

  function configureOneBatchAction() {
    let $actionButton;
    let val;
    if ($(".batch_action").length === 1) {
      $actionButton = $(".batch_action")
        .addClass("button-link js-one-action disabled")
        .clone();

      $(".batch_actions_selector").detach();
      $(".table_tools").prepend($actionButton).addClass("indent-left-2");

      $actionButton.click(function (event, inputs) {
        event.stopPropagation(); // prevent Rails UJS click event
        event.preventDefault();
        if (!$(this).hasClass("disabled")) {
          val = JSON.stringify(inputs);
          if (val) {
            $("#batch_action_inputs").removeAttr("disabled").val(val);
          } else {
            $("#batch_action_inputs").attr("disabled", "disabled");
          }
          $("#batch_action").val($(this).data("action"));
          $("#collection_selection").submit();
        }
      });

      $(document).on("change", ".paginated_collection :checkbox", function () {
        if ($(".paginated_collection :checkbox:checked").length) {
          $(".js-one-action").removeClass("disabled");
          $("#collection_selection").removeAttr("disabled", "disabled");
        } else {
          $(".js-one-action").addClass("disabled");
          $("#collection_selection").attr("disabled", "disabled");
        }
      });
    }
  }

  /* Make the dropdown logic work for active admin menu */
  $(".has_nested, .dropdown_menu").addClass("js-dropdown");
  $(".dropdown_menu .dropdown_menu_list").addClass("dropdown-menu");
  $(".has_nested > a").addClass("js-dropdown-trigger");
  $(".dropdown_menu_button").addClass("js-dropdown-trigger js-no-hover");
  $(".has_nested ul").addClass("js-dropdown-menu is-hidden").show();
  configureResponsiveTable();
  $(".js-dropdown").each(configureDropdown);

  configureTopScrollbar();

  $(window).on("resize", resizeTopScrollbar);

  // auto open main nav on mobile login
  if (window.location.hash === "#login") {
    $(
      ".main-nav .js-dropdown > .js-dropdown-trigger.show-small:visible"
    ).trigger("click");
  }

  configureOneBatchAction();

  function handleOpenDropdownMenus(event) {
    function isEscKeyUpEvent() {
      return event.type === "keyup" && event.key === "Escape";
    }

    function isOutsideDropdownClickEvent(obj) {
      // Exempt anything that is in the filter dropdown, including react datepicker
      return (
        event.type === "click" &&
        !$(event.target).hasClass("js-dropdown-trigger") &&
        !$(event.target).parent().hasClass("js-dropdown-trigger") &&
        !$(event.target).hasClass("js-dropdown-menu") &&
        !$(event.target).hasClass("select2-selection__choice__remove") &&
        !$(event.target).parents("[class^='react-calendar']").length > 0 &&
        $(obj).has(event.target).length === 0
      );
    }

    function closeDropdown(obj) {
      $(obj).addClass("is-hidden");
      $(obj)
        .parent()
        .find(".js-caret")
        .addClass("fa-caret-down")
        .removeClass("fa-caret-up");
      $(obj)
        .parent()
        .find(".js-dropdown-trigger")
        .removeClass("js-clicked-open");
    }

    $(".js-dropdown-menu").each(function (_, obj) {
      if (!$(obj).hasClass("is-hidden")) {
        if (isEscKeyUpEvent() || isOutsideDropdownClickEvent(obj)) {
          closeDropdown(obj);
        }
      }
    });
  }

  $(document).on("click", handleOpenDropdownMenus);
  $(document).on("keyup", handleOpenDropdownMenus);
});
