import { toPairs } from "lodash";
import PropTypes from "prop-types";

import ReplaceTemplate from "./ReplaceTemplate";
import styles from "./ReplaceTemplate.module.scss";

const ReplaceTemplates = ({ templates, uploadPath }) => (
  <div className={styles.templateWrapper}>
    {toPairs(templates).map(
      ([locale, { templateFilename, templateDownloadUrl }]) => (
        <ReplaceTemplate
          key={locale}
          {...{ locale, uploadPath, templateDownloadUrl, templateFilename }}
        />
      )
    )}
  </div>
);

ReplaceTemplates.propTypes = {
  templates: PropTypes.object.isRequired,
  uploadPath: PropTypes.string.isRequired,
};

export default ReplaceTemplates;
