import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  InputDropdown,
  InputText,
  Text,
} from "@heart/components";
import { Spinner } from "@heart/components/icon/Icon";
import { PropTypes } from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";
import BannerContainer from "@components/shared/banner/BannerContainer";

import PermissionRuleSets from "@graphql/queries/PermissionRuleSets.graphql";

const { t } = translationWithRoot("permissions.agency_relationships");

const CreatePermissionRuleSet = gql`
  mutation CreatePermissionRuleSet($label: String!, $templateId: String) {
    createPermissionRuleSet(input: { label: $label, templateId: $templateId }) {
      permissionRuleSet {
        id
        label
      }
    }
  }
`;

const CreatePermissionRuleSetForm = () => {
  const { data, loading, error: queryError } = useQuery(PermissionRuleSets);
  const [createPermissionRuleSet, { loading: adding, error: mutationError }] =
    useMutation(CreatePermissionRuleSet, {
      refetchQueries: [{ query: PermissionRuleSets }],
    });

  const create = ({ target }) => {
    const fd = new FormData(target);
    createPermissionRuleSet({
      variables: {
        label: fd.get("label"),
        templateId: fd.get("templateId") || null,
      },
    });
  };

  if (adding || loading) {
    return <Spinner />;
  }

  const error = queryError || mutationError;
  if (error) {
    return (
      <BannerContainer type="error" message={`Ooopsie! ${error.message}`} />
    );
  }

  const permissionRuleSets = data.permissionRuleSets || [];
  const permissionRuleSetDropdownOptions = permissionRuleSets.map(prs => ({
    label: prs.label,
    value: prs.id,
  }));

  return (
    <Form
      onSubmit={create}
      permissionRuleSetDropdownOptions={permissionRuleSetDropdownOptions}
    />
  );
};

const Form = ({ onSubmit, permissionRuleSetDropdownOptions }) => (
  <div className="contains-inputs">
    <Text textStyle="emphasis-200">Create Permission Rule Set</Text>
    <form onSubmit={onSubmit}>
      <Flex gap="100" column>
        <InputText
          label={t("permission_rule_set_label")}
          id="label"
          name="label"
          required={true}
          rows={1}
          type="text"
        />
        <InputDropdown
          id="templateId"
          name="templateId"
          label={t("existing_rule_set_to_copy")}
          values={permissionRuleSetDropdownOptions}
        />
        {/* to shrink button */}
        <div>
          <Button type="submit">Add</Button>
        </div>
      </Flex>
    </form>
  </div>
);

Form.propTypes = {
  permissionRuleSetDropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, id: PropTypes.number })
  ),
  onSubmit: PropTypes.func,
};

export default CreatePermissionRuleSetForm;
